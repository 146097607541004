@mixin loyalty-next-tier {
  .loyalty__panel__next-tier {
    &.is-guest,
    &.loyalty__has-max-tier,
    &.loyalty__has-pending-points {
      display: none;
    }
    &__title {
      padding-top: 10px;
      font-family: $font--avenir-heavy;
      font-size: 16px;
    }
    &-link {
      color: $color-gray;
      font-size: 16px;
      @include breakpoint($medium-up) {
        font-size: 14px;
      }
    }
  }
} // @mixin loyalty-next-tier
