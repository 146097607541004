@mixin loyalty-benefits {
  .loyalty__panel__benefits {
    &__title {
      @include loyalty-panel-title;
    }
    &__tiers {
      @include loyalty-clear;
    }
  }
  .loyalty__panel__get__points {
    &__status {
      text-align: center;
      margin-top: 50px;
      &-header {
        text-align: center;
      }
      &-subheader {
        @include swap_direction(padding, 10px 10px 0);
        font-size: 19px;
        font-family: $font--avenir-light;
        margin-bottom: 60px;
        @include breakpoint($medium-up) {
          @include swap_direction(margin, 0 auto 60px);
          max-width: 1024px;
          font-size: 25px;
          line-height: 38px;
        }
      }
    }
    &__faq {
      @include swap_direction(margin, 0 15px);
      @include swap_direction(padding, 20px 0);
      text-align: #{$ldirection};
      border-bottom: 1px solid $color-black;
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 20px 0 20px 20px);
        @include swap_direction(margin, 0 auto);
        max-width: 600px;
      }
      &-header {
        font-family: $font--avenir-heavy;
        font-size: 19px;
        background-image: url(/media/images/global/accordion-expand.png);
        background-repeat: no-repeat;
        background-position: top right;
      }
      &-header-text {
        padding-right: 30%;
        margin-bottom: 0;
        @include breakpoint($medium-up) {
          padding-right: 30%;
        }
      }
      &-content {
        font-family: $font--avenir-medium;
        font-size: 16px;
        p {
          margin-bottom: 0;
        }
      }
    }
    &__faq-block {
      &.block-active {
        .loyalty__panel__get__points__faq {
          &-header {
            background-image: url(/media/images/global/accordion-collapse.png);
          }
          &-content {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .loyalty__panel {
    &__all__faq__status {
      @include swap_direction(margin, 40px 0);
      text-align: center;
      &-button {
        font-family: $font--avenir-heavy;
        font-size: 19px;
      }
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 60px 0);
      }
    }
  }
} // @mixin loyalty-benefits
