.menu__quicklink__container {
  margin-bottom: 20px;
  height: 50px;
  background-color: $color-lightest-gray;
  @include breakpoint($large-up) {
    display: none;
    margin-bottom: 0;
  }
  .sticky__menu {
    float: #{$ldirection};
    width: 20%;
    padding: 6px 4px 2px 4px;
    text-align: center;
    .icon {
      font-size: 22px;
      margin-bottom: 2px;
      line-height: 23px;
    }
    &__items {
      @include clearfix;
      background-color: $color-lightest-gray;
      color: $color-black;
    }
    &__link {
      display: block;
    }
    &__copy {
      display: block;
      font-family: $font--avenir-medium;
      font-size: 11px;
    }
    &__subnav__active {
      .site-email-signup {
        display: block;
      }
    }
    &__formatter {
      display: block;
      .sticky__menu__subnav {
        bottom: 0;
      }
    }
  }
  .site-email-signup {
    display: none;
  }
}

.sticky__menu__formatter {
  .sticky__menu__subnav {
    &__content {
      height: 220px;
      background: url(/media/images/global/email-signup-dropdown-footer-background-mobile.jpg) no-repeat center center;
    }
    &--close {
      color: $color-black;
    }
  }
}

.site-header__section--nav {
  .mobile-gnav-active & {
    padding-top: 0;
  }
}
