@mixin loyalty-join {
  .loyalty__panel__join {
    @include loyalty-panel;
    display: block;
    clear: both;
    text-align: center;
    margin-top: 30px;
    background: url(/media/images/global/account_landing_loyalty_banner.png) no-repeat;
    background-size: cover;
    background-position: center;
    float: #{$ldirection};
    width: 100%;
    &.is-member {
      display: none;
    }
    &__form {
      &-join-button {
        margin-top: 210px;
        .button {
          border: 1px solid $color-white;
          text-transform: initial;
        }
      }
      &-terms-block {
        @include swap_direction(margin, 10px 0 25px);
      }
      &-faq-button {
        margin-right: 10px;
        color: $color-white;
      }
      &-terms-button {
        color: $color-white;
      }
    }
    &--member {
      padding-bottom: 40px;
    }
  }
  .sign-in-page {
    .new-account__fieldset {
      .form-item--newsletter,
      .form-item--loyalty {
        .label:before {
          margin-top: 4px;
        }
        .icon {
          font-size: 23px;
          margin-right: 10px;
        }
        .label-content-text {
          vertical-align: middle;
        }
      }
    }
  }
  .loyalty-overlay {
    .loyalty_benefits {
      margin-bottom: 20px;
      &_container {
        padding-top: 50px;
        text-align: center;
      }
      &_terms-button {
        font-family: $font--avenir-book;
        font-size: 14px;
        color: $color-gray;
      }
      &_rewards-button-container {
        margin-top: 15px;
        .button {
          text-transform: initial;
        }
      }
    }
  }
  .loyalty__panel__tout {
    @include swap_direction(margin, 30px 25px 10px);
    margin-top: 45px;
    text-align: center;
    @include breakpoint($medium-up) {
      @include swap_direction(margin, 64px auto 45px);
      max-width: 930px;
    }
    &-message {
      font-size: 19px;
      font-family: $font--avenir-light;
      @include breakpoint($medium-up) {
        font-size: 25px;
        line-height: 41px;
      }
    }
  }
  .loyalty_marketing {
    text-align: center;
    .loyalty__panel__join__form {
      &-join-button {
        margin-top: 0px;
        @include breakpoint($medium-up) {
          .button {
            font-size: 19px;
          }
        }
      }
      &-join-sigin,
      &-terms {
        font-family: $font--avenir-book;
        font-size: 14px;
        margin-top: 11px;
      }
      &-terms {
        margin-bottom: 30px;
      }
    }
  }
  .loyalty-join-content {
    @include swap_direction(padding, 20px 0);
    .loyalty_join {
      margin-bottom: 20px;
      .label-content {
        .icon {
          margin-right: 10px;
        }
      }
    }
  }
} // @mixin loyalty-join
