// landing page

.offer__landing__carousel {
  @include swap_direction(margin, 26px 0 0 0);
  .basic-carousel__title {
    max-width: 1000px;
    margin: 0 auto;
    border-bottom: 1px solid $color-light-gray;
  }
  .basic-carousel__header {
    padding: 0;
    @include breakpoint($medium-up) {
      padding: 0 20px;
    }
  }
  .header--large {
    font-size: 41px;
    line-height: 1.17;
    letter-spacing: -0.01em;
  }
  .offer__tout__button {
    margin-top: 10px;
  }
  .offer__tout__details {
    @include content-container;
  }
  .offer__tout__details__content {
    padding: 20px;
  }
  .basic-carousel__button {
    font-family: $font--avenir-medium;
    font-size: 12px;
    border-bottom: 1px solid $color-gray;
    text-transform: none;
    @include icon('caret--down', after);
    &:hover {
      color: $color-gray;
      border-width: 3px;
      text-decoration: none;
    }
  }
}

.offer__landing__tout {
  @include swap_direction(margin, 50px auto);
  padding: 0 10px;
  @include breakpoint($medium-up) {
    @include swap_direction(margin, 60px auto 58px auto);
    padding: 0;
    max-width: 1140px;
  }
  .basic-tout--overlay {
    .basic-tout__content {
      color: $color-white;
      @include breakpoint($medium-up) {
        color: $color-black;
        width: 50%;
        left: 50%;
      }
    }
  }
  .basic-tout__headline.header--large {
    @include breakpoint($small-only) {
      font-size: 53px;
      line-height: 1.32;
      letter-spacing: 0.14em;
      text-transform: uppercase;
    }
  }
}

.offer__landing__offers {
  margin-bottom: 26px;
  .basic-grid__header {
    max-width: 1000px;
    margin: 0 auto 20px auto;
    padding: 22px 0 0 0;
    border-top: 1px solid $color-light-gray;
  }
  .basic-grid__title {
    text-transform: uppercase;
  }
  .basic-tout__image {
    @include breakpoint($small-only) {
      max-height: 228px;
    }
  }
  .header--medium {
    font-size: 28px;
    line-height: 1.25;
    letter-spacing: -0.01em;
  }
}

.offer__landing__offers,
.offer__landing__extras {
  .basic-grid-formatter--gutters {
    .basic-grid--large-cols-3 {
      .basic-grid__item {
        @include breakpoint($medium-only) {
          width: grid-item-width(3, 2px);
        }
        @include breakpoint($large-up) {
          width: grid-item-width(3, 2px);
        }
      }
    }
    .basic-grid--large-cols-2 {
      .basic-grid__item {
        @include breakpoint($medium-only) {
          width: grid-item-width(2, 2px);
        }
        @include breakpoint($large-up) {
          width: grid-item-width(2, 2px);
        }
      }
    }
  }
}

.offer__landing__extras {
  padding: 0 10px;
  @include breakpoint($medium-up) {
    padding: 0;
  }
  .basic-tout__image.pc-hidden {
    border: 1px solid $color-light-gray;
  }
  .basic-grid--large-cols-2 {
    @include breakpoint($medium-up) {
      padding: 1px 0;
      background: $color-light-gray;
    }
  }
  .header--large {
    font-size: 41px;
    line-height: 1.17;
    letter-spacing: -0.01em;
  }
}
