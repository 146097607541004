@mixin loyalty-perks {
  .loyalty__panel__perk {
    margin-bottom: 45px;
    &--mobile {
      @include breakpoint($medium-up) {
        display: none;
      }
    }
    &__title {
      @include loyalty-panel-title;
      @include swap_direction(margin, 15px 15px 20px);
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 15px 15px 20px);
        text-align: center;
      }
    }
    .smartbar,
    .brilliantbar,
    .geniusbar {
      &.accordion-active {
        background: $color-thick-gray;
        .smartbar-header,
        .brilliantbar-header,
        .geniusbar-header {
          background: none;
          border-bottom: 0;
        }
        .smartbar-header-banner,
        .brilliantbar-header-banner,
        .geniusbar-header-banner {
          background-image: url(/media/images/global/accordion-collapse.png);
        }
      }
      @include breakpoint($medium-up) {
        float: #{$ldirection};
        width: 33%;
      }
      &-header {
        @include swap_direction(padding, 20px);
        background-image: url(/media/images/global/marketing-page-tier1-background-mobile.png);
        background-size: cover;
        background-repeat: no-repeat;
        border-top: 1px solid $color-light-gray;
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 0);
          @include swap_direction(margin, 0 7px);
          background-image: url(/media/images/global/marketing-page-tier1-background-desktop.png);
          border-top: 0;
          position: relateive;
          text-align: center;
          &-banner {
            min-height: 60px;
          }
        }
        &-banner {
          background-image: url(/media/images/global/accordion-expand.png);
          background-repeat: no-repeat;
          background-position: #{$rdirection};
          @include breakpoint($medium-up) {
            background: none;
          }
        }
        &-level,
        &-points {
          font-family: $font--avenir-book;
          font-size: 19px;
          display: inline;
          padding-right: 5px;
          @include breakpoint($medium-up) {
            display: block;
          }
        }
        &-points {
          font-family: $font--avenir-light;
          color: $color-link-gray;
          font-size: 14px;
        }
      }
      &-list {
        @include swap_direction(padding, 15px 15px 15px 25px);
        p {
          font-family: $font--avenir-light;
          font-size: 19px;
          padding-left: 25px;
          background-image: url(/media/images/global/list_icon.png);
          background-repeat: no-repeat;
          background-position: #{$ldirection};
          @include breakpoint($medium-up) {
            padding-left: 20px;
          }
        }
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 45px 15px 15px 25px);
          margin-right: 15px;
          background-image: url(/media/images/global/smart_pc_background.png);
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
    .brilliantbar-header {
      background-image: url(/media/images/global/marketing-page-tier2-background-mobile.png);
      @include breakpoint($medium-up) {
        background-image: url(/media/images/global/marketing-page-tier2-background-desktop.png);
      }
    }
    .geniusbar-header {
      border-bottom: 1px solid $color-light-gray;
      background-image: url(/media/images/global/marketing-page-tier3-background-mobile.png);
      @include breakpoint($medium-up) {
        border-bottom: 0;
        background-image: url(/media/images/global/marketing-page-tier3-background-desktop.png);
      }
    }
    &--marketing {
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0 auto);
        max-width: 930px;
        .brilliantbar-header-level {
          margin-bottom: 15px;
        }
      }
      .loyalty__panel__perk__title {
        border-bottom: 0;
        text-align: center;
        font-size: 28px;
        @include breakpoint($medium-up) {
          font-size: 41px;
        }
      }
    }
    @include breakpoint($medium-up) {
      .brilliantbar-list {
        background-image: url(/media/images/global/brilliant_pc_background.png);
      }
      .geniusbar-list {
        background-image: url(/media/images/global/genius_pc_background.png);
      }
    }
  }
  .loyalty__panel__customer__buttons {
    &__container {
      @include swap_direction(margin, 15px);
      text-align: center;
      .button {
        width: 100%;
        margin-bottom: 15px;
        font-size: 19px;
        text-transform: initial;
        @include breakpoint($medium-up) {
          width: 300px;
        }
      }
      @include breakpoint($medium-up) {
        margin-bottom: 60px;
      }
    }
    &-terms {
      @include breakpoint($medium-up) {
        margin-right: 15px;
      }
    }
  }
} // @mixin loyalty-perks
