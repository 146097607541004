$banner-height: 50px !default;
$header-height: 115px;
$pc-banner-height: 50px;

.site-header__main {
  @include breakpoint($medium-up) {
    height: 65px;
  }
}

.site-header {
  &__section {
    &--branding {
      @include breakpoint($landscape-up) {
        width: 18%;
      }
    }
    &--nav {
      @include breakpoint($medium-up) {
        width: 54%;
      }
    }
  }
  @include breakpoint($medium-up) {
    &__section {
      &--banner__utility {
        line-height: 2.5;
        .content {
          float: #{$rdirection};
          display: inline-block;
        }
        .basic-responsive,
        .gnav-util--account,
        .gnav-util--cart {
          display: block;
          width: auto;
          float: #{$ldirection};
          padding: 0 5px;
          a {
            color: $color-black;
            display: inline-block;
            line-height: inherit;
          }
          .cart-block--empty {
            .cart-block__footer__button--checkout {
              display: none;
            }
          }
          .cart-block--nonempty,
          .cart-block__footer__button--checkout {
            display: block;
          }
          .cart-block__title-link {
            color: $color-black;
          }
          .cart-block__footer__button {
            color: $color-white;
          }
        }
        .gnav-util--account,
        .gnav-util--cart {
          margin: 0;
          .gnav-util__icon {
            line-height: 2.9;
          }
          .gnav-util__icon--cart .gnav-util__icon__cart-count {
            margin-top: 12px;
            padding-top: 7px;
            position: relative;
          }
          .gnav-util__icon--account {
            text-transform: uppercase;
            font-size: 13px;
            margin-top: 8px;
            font-weight: bold;
          }
        }
        .gnav-util--account {
          padding-left: 10px;
          margin-left: 3px;
          .icon--utility_outlined_my-account {
            display: none;
          }
        }
        .site-header__bbrewards {
          margin: 0;
          padding: swap_direction(padding, 0 5px 0 0);
          position: relative;
        }
      }
    }
    &__wrap {
      padding: 0 10px;
    }
    &__primary__menu > .menu__formatter__list--lvl-1a > .menu__item--lvl-1a {
      padding: 0 15px 5px;
    }
  }
  .banner__carousel__item {
    &__link {
      .icon {
        display: none;
      }
    }
  }
  @include breakpoint($medium-up) {
    background-color: $color-white;
    html.pro-user & {
      background-color: $color-black;
    }
    &__main {
      background-color: $color-black;
    }
    &__wrap {
      background-color: $color-white;
      color: $color-black;
      html.pro-user & {
        background-color: $color-black;
      }
    }
    &__banner {
      height: $pc-banner-height;
    }
    &__section {
      &--banner__utility,
      &--offer {
        background-color: inherit;
        color: inherit;
      }
      &--banner__utility {
        height: $pc-banner-height;
        .icon {
          font-size: 21px;
        }
      }
    }
    &__section--offer {
      height: $pc-banner-height;
      .banner__carousel__items {
        @include swap_direction(margin, 0);
        height: $pc-banner-height;
        line-height: $pc-banner-height;
      }
      .banner__carousel__item {
        padding-left: 20px;
        position: relative;
        .icon {
          display: inline;
          text-decoration: none;
          position: absolute;
          left: 0;
          line-height: $pc-banner-height;
          font-size: 22px;
          top: 0;
        }
      }
      .banner-carousel-item__link {
        color: inherit;
        font-family: $font--avenir-heavy;
        line-height: 20px;
        vertical-align: middle;
        display: inline-block;
        &:hover {
          color: $color-gray;
        }
      }
    }
    &__store__locator,
    &__contact,
    &__bbrewards {
      text-align: center;
      line-height: 30px;
      color: inherit;
      margin-left: 25px;
      float: #{$rdirection};
      &-text {
        display: block;
        @include breakpoint($medium-up) {
          display: none;
        }
        font-family: $font--avenir-medium;
        font-size: 10px;
        line-height: 10px;
        color: inherit;
      }
    }
    &__bbrewards {
      .icon {
        margin-top: 4px;
        line-height: 29px;
      }
    }
    &__contact {
      @include swap_direction(margin, 0 0 0 25px);
      .icon {
        line-height: 27px;
      }
    }
    &__store__locator {
      @include swap_direction(margin, 0);
    }
    &__account {
      @include swap_direction(padding, 0 20px 0 0);
      @include swap_direction(margin, 10px 20px 10px 0);
      padding: 10px;
      margin-right: 0px;
      color: inherit;
      float: #{$rdirection};
      border-right: 1px solid $color-black;
      line-height: 10px;
      .site-header__account__link {
        color: inherit;
        display: block;
        line-height: 20px;
        &:hover {
          color: $color-gray;
        }
      }
      &__state {
        line-height: 10px;
      }
    }
  }
}

.page-wrapper {
  &--with-offer-banner {
    @include breakpoint($medium-up) {
      padding-top: $header-height;
    }
  }
}

body.checkout-optimize {
  &.active-panel-registration {
    .page-wrapper {
      @include breakpoint($medium-up) {
        padding-top: $header-height;
      }
    }
  }
}

.enable_gnav__revamp {
  .search-form {
    @include breakpoint($medium-up) {
      padding: 11px 10px;
      position: unset;
    }
  }
  .esearch-nav__suggestions-wrapper {
    @include breakpoint($medium-up) {
      background: $color-white;
      color: $color-black;
      width: 100%;
      position: absolute;
      top: 65px;
      left: 0;
      z-index: 20;
    }
  }
  input[type='text'].esearch-nav__form-field,
  input[type='text'].search-form__field {
    @include breakpoint($medium-up) {
      background: $color-black;
      border: 1px solid $color-white;
      text-align: left;
      padding-right: 10px;
      padding-left: 35px;
      max-width: 185px;
      height: 39px;
      margin-top: 2px;
      color: $color-white;
    }
  }
  .esearch-nav__form-fields {
    .esearch-nav__form-submit {
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
  .icon--search {
    @include breakpoint($medium-up) {
      right: auto;
      left: 0;
      background: none;
      font-size: 18px;
      width: 36px;
      height: 40px;
      line-height: 2.3;
    }
  }
}

.gnav-util--account {
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 11px;
    width: 1px;
    height: 25px;
    background: $color-black;
  }
  .gnav-util__icon--account {
    .gnav-util__carrot {
      @include breakpoint($medium-up) {
        right: 10px;
      }
    }
  }
  .gnav-util__content {
    @include breakpoint($medium-up) {
      width: 345px;
      position: fixed;
      top: auto;
      right: 70px;
      z-index: 9999;
    }
    &--account {
      .signin-block__title {
        @include breakpoint($medium-up) {
          margin-bottom: 5px;
        }
      }
      .signin-block__register {
        @include breakpoint($medium-up) {
          margin-bottom: 0;
        }
      }
      input[type='submit'] {
        @include breakpoint($medium-up) {
          padding-top: 10px;
        }
      }
      input[type='password'] {
        @include breakpoint($medium-up) {
          margin-bottom: 10px;
        }
      }
    }
    .social-login__container {
      @include breakpoint($medium-up) {
        padding-top: 0;
      }
      label::before {
        @include breakpoint($medium-up) {
          line-height: 1.5;
        }
      }
      .social-login__terms {
        @include breakpoint($medium-up) {
          margin-top: 0;
        }
      }
    }
  }
}

.gnav-util__content--cart {
  @include breakpoint($medium-up) {
    width: 350px;
    position: fixed;
    right: 20px;
    top: auto;
  }
}

.enable-gnav__revamp {
  .gnav-util__lotions-icon {
    @include breakpoint($medium-up) {
      background-image: url('/media/export/cms/nav_icons/location_black.png');
      height: 25px;
      width: 16px;
      background-size: 100%;
      margin-bottom: 1px;
      &:hover {
        background-image: url('/media/export/cms/nav_icons/location_gray.png');
      }
    }
  }
  .gnav-util__cart-icon {
    @include breakpoint($medium-up) {
      background-image: url('/media/export/cms/nav_icons/shoppingbag_black.png');
      height: 26px;
      width: 20px;
      background-size: 100%;
      margin-bottom: 1px;
      &:hover {
        background-image: url('/media/export/cms/nav_icons/shoppingbag_gray.png');
        color: $color-gray;
      }
    }
  }
  .site-header__account {
    @include breakpoint($medium-up) {
      a.site-header__my--account {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
      }
    }
  }
  .gnav-util__text--account {
    color: $color-black;
    @include breakpoint($medium-up) {
      &:hover {
        color: $color-gray;
      }
    }
  }
  .gnav-util--cart {
    .gnav-util__icon--cart.active {
      .gnav-util__carrot {
        @include breakpoint($medium-up) {
          top: 39px;
        }
      }
    }
  }
  .site-header {
    &__logo__link {
      @include breakpoint($large-up) {
        bottom: 40px;
      }
    }
    &__section--branding {
      .site-header__logo__container {
        @include breakpoint($medium-only) {
          @include swap_direction(margin, 0 0 0 25px);
        }
      }
    }
  }
}
