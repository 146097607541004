///
/// @file base/_typography--helpers.scss
///
/// \brief Reusable, generic typographic styles used throughout the site.
///
/// Guidelines:
///
/// Generic classes we can use sitewide for display elements.
/// WARNING do not change values unless you know why you are here
/// these classes are used in mustache for touts
/// @setup new brand - apply consistently to match your designs

/// sizes only
.header--extra-large {
  @include header--extra-large;
}

.header--extra-large-alt {
  @include header--extra-large-alt;
}

.header--large {
  @include header--large;
}

.header--medium {
  @include header--medium;
}

.header--small {
  @include header--small;
}

.t1,
.text--large {
  @include t1;
}

.t2,
.text--small {
  @include t2;
}

/// font faces
/// @setup new brand - can change to font names
/// example: .font--franklin or .font--adobe-caslon

/// serif
.font--serif {
  font-family: $font--serif;
}

/// sans-serif
.font--sans {
  font-family: $font--avenir-roman;
}
/// mono
.font--mono {
  font-family: $font--mono;
}

/// Avenir
.font--avenir-light {
  font-family: $font--avenir-light;
}

.font--avenir-book {
  font-family: $font--avenir-book;
}

.font--avenir-roman {
  font-family: $font--avenir-roman;
}

.font--avenir-medium {
  font-family: $font--avenir-medium;
}

.font--avenir-heavy {
  font-family: $font--avenir-heavy;
}

.font--avenir-black {
  font-family: $font--avenir-black;
}

/// colors
.font-color--black {
  color: $color-black;
}

.font-color--white {
  @include font-smoothing;
  color: $color-white;
}

.font-color--gray {
  color: $color-gray;
}

.button {
  @include extended-button;
}

// Mobile + Desktop Large button
.button--large {
  @include button-large;
  @include button-large--desktop;
}

///
/// Form Buttons
///
button,
input[type='submit'],
input[type='reset'] {
  @include button;
  @include appearance(none);
  border-radius: 0;
  border-width: 1px;
  vertical-align: middle;
}

button {
  padding-top: 9px;
  padding-bottom: 9px;
  @include breakpoint($medium-up) {
    padding-top: 4px;
    padding-bottom: 2px;
  }
}

.link {
  @include extended-link;
}
