.viewcart {
  .paypal-smart-button {
    display: flex;
    column-gap: 8px;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    padding: 1px;
    @include breakpoint($medium-up) {
      padding: 0 2px;
    }
    &.paypal-loading {
      position: relative;
      background: $color-white url(/media/images/global/ajax-loading.gif) no-repeat center center;
      text-align: center;
    }
    &.pc-paypal-button {
      width: 40%;
    }
    &.paypal-disabled {
      border-radius: 4px;
      border: 1px solid $color-black;
      opacity: 0.2;
      text-align: center;
    }
    &__img {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.order-summary-content {
  &__afterpay-learnmore {
    cursor: pointer;
    border: none;
  }
  &__afterpay-info {
    width: 17px;
    height: 17px;
    display: inline-block;
    border-radius: 50%;
    background: $color-black;
    color: $color-white;
    font-size: 11px;
    line-height: 1.6;
    text-transform: lowercase;
    text-align: center;
    text-decoration: none;
  }
}
.afterpay {
  .basic-tout__image {
    width: 100px;
    height: 73px;
    margin: 0 auto 10px;
  }
}
.afterpay-working {
  .header--medium {
    text-align: center;
  }
}
.LPMcontainer {
  .LPMimage {
    display: block;
  }
  .LPMlabel {
    color: $color-white !important;
  }
}

.hair-quiz__question--desiredhairstyle {
  .hair-quiz__illustration {
    &.slick-active {
      padding-bottom: 40px;
    }
  }
}
