/* Mixin function for review star Background image */

$pr-height-value: 11px;
$pr-width-value: 11px;
$pr-large-height-value: 18px;
$pr-large-width-value: 17px;
@mixin pr-background-image($pr-h, $pr-w) {
  $pr-count: (0, 25, 50, 75, 100);
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/products/stars/Bb_scribbled_star_#{$pr-value}.png');
      height: $pr-h !important;
      width: $pr-w !important;
    }
  }
}
/* Mixin function for pr-button display */

@mixin pr-button-display {
  background: $color-black;
  color: $color-white;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
}
.site-header {
  .pr-snippet-stars-png {
    @include pr-background-image($pr-height-value, $pr-width-value);
  }
  .pr-snippet-read-and-write {
    .pr-snippet-write-review-link {
      padding-#{$ldirection}: 10px !important;
    }
  }
}
/* start of main power review container */
#power_review_container {
  @include swap_direction(padding, 0 10px);
  .product-full__header,
  .product-full__details {
    &__image-link {
      text-decoration: none;
    }
  }
}
/* Common Section render for MPP, SPP, Review, Endeca search and Display Page */
#pr-write,
#pr-reviewdisplay,
.product-grid__content,
.product-full__header,
.product-full__details,
#pr-reviewsnippet,
.search-wrapper__results,
.page-wrapper {
  .p-w-r .pr-review-snapshot,
  .p-w-r .pr-review-display {
    background: none;
  }
  .p-w-r {
    margin: 0;
    /* To display a Star background Image */
    a {
      color: $color-black;
      text-decoration: underline;
      &:hover {
        color: $color-light-black;
      }
    }
    span,
    p {
      font-size: 16px;
      color: $color-black;
      line-height: 1;
    }
    h1 {
      font-size: 19px;
    }
    .pr-histogram-count {
      font-weight: 400;
    }
    .pr-snippet-stars-reco-inline .pr-snippet-read-and-write a {
      font-size: 12px;
      color: $color-reviews;
      border-left-color: $color-reviews;
      margin-#{$rdirection}: 0;
    }
    .pr {
      &-rating-stars div[role='radio'] {
        margin-#{$rdirection}: 4px !important;
      }
      &-textinput,
      &-textarea {
        @include input-placeholder {
          font-size: $base-font-size;
        }
      }
      &-alert-container,
      &-header-product-img,
      &-header-required,
      &-logo-container,
      &-header-product-name,
      &-review-snapshot-header-intro,
      &-snippet-rating-decimal,
      &-cons-form-group,
      &-bestuses-form-group {
        display: none;
      }
      &-snippet-rating-decimal {
        display: inline-block;
        border: none;
        font-size: 11px;
        line-height: 1;
      }
      &-table-cell {
        @include swap_direction(padding, 0);
      }
      &-helper-text {
        color: $color-black;
      }
      &-clear-all-radios {
        color: $color-black;
        letter-spacing: 0;
        &:hover {
          color: $color-black;
          text-decoration: underline;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      &-snippet {
        text-align: center;
      }
      /* To Change Bar Graph */
      &-review-snapshot {
        .pr-histogram-stars {
          height: 45px;
        }
        .pr-ratings-histogram {
          &-bar {
            height: 35px;
          }
          &-barValue {
            height: 35px;
            background: $color-black;
            border-radius: 0;
          }
        }
        &-block {
          border: 1px solid transparent;
          @include swap_direction(margin, 10px);
          min-height: auto;
          @include breakpoint($medium-down) {
            @include swap_direction(margin, 0);
            min-height: 90px;
          }
          dt {
            display: none;
          }
        }
      }
      &-accessible-btn {
        border-radius: 0;
        font-weight: 400;
        &:hover {
          background: $color-light-black;
        }
      }
      &-header-table {
        .pr-header-title {
          text-transform: uppercase;
          font-weight: 400;
        }
      }
      &-snippet-stars-reco {
        &-inline {
          .pr-snippet-read-and-write {
            margin-top: 10px;
            span {
              margin-bottom: 15px;
              font-family: $font--avenir-roman;
            }
            .spp__container & {
              a {
                text-decoration: underline;
                @include swap_direction(padding, 0 8px);
                color: $color-black;
                @include breakpoint($medium-down) {
                  @include swap_direction(padding, 0 10px);
                }
              }
            }
            .pr-snippet-write-review-link {
              border-#{$ldirection}: 1px solid $color-light-black;
              .spp-cross-sells__products &,
              .product-grid-wrapper &,
              .search-wrapper & {
                display: none;
              }
            }
          }
          &.pr-snippet-minimal {
            .pr-snippet-stars-container {
              display: inline-block;
              .product-full__header &,
              .product-full__details & {
                display: block;
              }
            }
          }
        }
      }
      /* To display a Review description posted by user */
      &-review-display {
        dt,
        dd,
        dl {
          color: $color-black;
        }
        &.pr-rd-display-tablet {
          .pr-review {
            border-bottom: 1px dotted $color-grey;
          }
        }
        .pr-rd-description-text {
          color: $color-black;
        }
        .pr-rd-main-header {
          @include swap_direction(padding, 0 0 20px);
          border-bottom: 1px solid $color-light-gray;
          border-top: none;
          margin-bottom: 0;
          background: none;
          display: flex;
          flex-flow: column;
          h1 {
            display: none;
          }
          .pr-rd-review-total {
            display: none;
          }
          .pr-rd-sort-group {
            font-family: $font--avenir-heavy;
            font-size: 16px;
            font-weight: 400;
            label {
              text-transform: uppercase;
              color: $color-black;
              font-size: 14px;
              font-weight: 400;
            }
            select {
              text-transform: lowercase;
              font-weight: 400;
              optgroup {
                font-weight: 400;
              }
            }
          }
          .pr-rd-review-header-contents {
            background: transparent;
            padding: 15px 0;
            order: 2;
            display: flex;
            flex-flow: column;
            align-items: self-start;
            @include breakpoint($medium-up) {
              flex-flow: row;
              align-items: center;
            }
            &:before {
              content: 'filter: ';
              font-family: $font--avenir-heavy;
              font-weight: 400;
              font-size: 16px;
              margin-#{$rdirection}: 10px;
              float: $ldirection;
              text-transform: uppercase;
            }
            .pr-multiselect {
              &.pr-multiselect-button-describeyourself {
                display: none;
              }
              .pr-multiselect-button {
                border: none;
                padding-#{$ldirection}: 0;
                .pr-multiselect-count {
                  font-size: 13px;
                  background-color: $color-black;
                  color: $color-white;
                }
                .pr-multiselect-button-label {
                  font-size: 16px;
                }
              }
              .pr-multiselect-options {
                .pr-multiselect-item-label {
                  &:before {
                    content: '';
                  }
                }
              }
            }
          }
          .pr-rd-main-header-search-sort {
            display: flex;
            flex-flow: column;
            align-items: end;
            order: 1;
            width: 100%;
            .pr-rd-review-header-sorts {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin: 20px 0;
              width: 100%;
              margin-bottom: 0;
              @include breakpoint($medium-up) {
                justify-content: end;
                margin-bottom: 20px;
              }
              &:before {
                content: 'SORT BY: ';
                font-family: $font--avenir-heavy;
                font-weight: 400;
                font-size: 16px;
                margin-#{$rdirection}: 10px;
              }
              .pr-rd-sort-group {
                display: flex;
                align-items: center;
                padding: 0;
                &:first-child {
                  margin-#{$rdirection}: 10px;
                }
                .pr-rd-sort {
                  border: none;
                  width: 132px;
                  min-width: auto;
                  font-size: 16px;
                  font-family: $font--avenir-medium;
                }
              }
            }
            .pr-rd-main-header-search {
              width: auto;
              .pr-rd-search-container {
                .pr-rd-search-reviews-input {
                  display: flex;
                }
                width: 100%;
                input {
                  width: 75% !important;
                  margin-#{$rdirection}: 15px;
                  height: 46px !important;
                  &:focus+button span svg path {
                    fill: $color-white;
                  }
                }
                .pr-rd-search-reviews-icon-button {
                  width: 64px;
                  height: 46px;
                  position: relative;
                  background-color: $color-black;
                }
                @include breakpoint($medium-up) {
                  width: 336px;
                }
              }
            }
          }
        }
      }
    }
    /* For Review Thank You page */
    .thank-you-page {
      h2.headline {
        text-transform: uppercase;
        color: $color-black;
      }
      .pr-rating {
        &-number {
          border: 1px solid $color-dark-gray;
        }
        &-date {
          border: none;
        }
      }
      .title {
        color: $color-black;
        text-transform: capitalize;
      }
      .pr-dark {
        color: $color-black;
        font-weight: bold;
      }
    }
  }
}
/* Spp Page Review Container */
.spp__container {
  .spp_customer_reviews {
    border-top: 1px solid $color-light-gray;
    @include swap_direction(padding, 35px 0 0 0);
    max-width: 1280px;
    @include swap_direction(margin, 0 auto);
    @include breakpoint($medium-down) {
      @include swap_direction(padding, 20px 0 0);
      border: none;
    }
    .p-w-r {
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 20px 0);
    }
    .p-w-r .pr-snippet .pr-snippet-stars-png .pr-rating-stars {
      @include breakpoint($medium-down) {
        background: $color-bg;
      }
    }
  }
  .spp_reviews {
    @include swap_direction(padding, 0 40px);
    &_heading {
      float: $ldirection;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 40px;
      }
      @include breakpoint($medium-down) {
        float: none;
      }
      h2 {
        text-transform: capitalize;
        @include h2;
        font-weight: 400;
        margin-bottom: 0px;
        @include breakpoint($medium-down) {
          text-align: center;
        }
      }
    }
    &_button {
      text-align: #{$rdirection};
      float: $rdirection;
      padding-#{$rdirection}: 40px;
      a {
        @include swap_direction(padding, 10px 25px 10px 25px);
        font-weight: 400;
        @include breakpoint($medium-down) {
          margin-top: 30px;
          width: 100%;
        }
      }
      @include breakpoint($medium-down) {
        padding: 10px 10px 0 10px;
        float: none;
      }
      input[type='submit'] {
        letter-spacing: 0px;
      }
    }
    @include breakpoint($medium-down) {
      @include swap_direction(padding, 0 10px);
    }
  }
  .product-full__header,
  .product-full__details {
    .pr-review-snippet-container {
      font-family: $font--avenir-roman;
      .pr-snippet-stars-png {
        @include pr-background-image($pr-height-value, $pr-width-value);
      }
      .pr-snippet-stars-reco-reco {
        display: none;
      }
      .pr-snippet-stars-reco-inline {
        @include swap_direction(margin, 0 0 10px 0);
      }
    }
  }
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet {
        text-align: center;
      }
      &.pr-snippet-compact {
        .pr-snippet-stars-reco-reco,
        .pr-snippet-read-and-write {
          margin-top: 5px;
          a {
            @include swap_direction(margin, 0);
          }
        }
      }
    }
  }
  #pr-reviewdisplay {
    @include swap_direction(padding, 30px 20px);
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 30px 0);
    }
    .spp_reviews_heading,
    .spp_reviews_button {
      display: none;
    }
    .p-w-r {
      clear: both;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      .pr-ggl_show-more-btn-container,
      .pr-ggl {
        display: none;
      }
      .pr-review-snapshot-simple,
      .pr-snippet-standard,
      .pr-snippet-compact,
      .pr-snippet-minimal {
        .pr-review-snapshot-snippets {
          .pr-snippet-read-and-write a {
            display: none;
          }
        }
        .pr-snippet-reco-to-friend-green {
          background: none;
          & circle {
            display: none;
          }
          .pr-checkbox-icon {
            display: none !important;
          }
          .pr-reco-value {
            @include h3;
            @include swap_direction(margin, 0 0 10px 15px);
            @include breakpoint($medium-down) {
              @include swap_direction(margin, 0);
              font-size: 28px;
            }
          }
        }
        .pr-reco-to-friend-message {
          font-size: 16px;
          line-height: 24px;
          @include breakpoint($medium-down) {
            width: 80%;
            @include swap_direction(padding, 0);
          }
        }
      }
      .pr-rd-bold {
        font-family: $font--avenir-heavy;
        font-weight: 400;
      }
      .pr-rd-description {
        font-family: $font--avenir-roman;
        .pr-rd-bold {
          font-family: $font--avenir-heavy;
        }
        .pr-rd-inner-side-content-block {
          p {
            @include swap_direction(padding, 0 0 5px);
            font-size: $base-font-size;
            font-family: $font--avenir-heavy;
            font-weight: 400;
            time {
              color: $color-black;
              font-weight: 400;
            }
            .pr-rd-bold {
              font-family: $font--avenir-roman;
              font-weight: normal;
            }
          }
        }
      }
      .pr-accordion-btn {
        letter-spacing: 0px;
        font-weight: 400;
        font-family: $font--avenir-heavy;
        text-transform: capitalize;
        span {
          font-size: 16px;
          font-family: $font--avenir-heavy;
          font-weight: 400;
        }
        .pr-caret-icon__line {
          stroke: $color-black;
        }
      }
      .pr-accordion-content {
        dl {
          &:first-child {
            display: none;
          }
          &:nth-child(4) {
            display: none;
          }
        }
      }
      .pr-review {
        border-bottom: 1px solid $color-light-gray;
        background-color: $color-lighter-gray;
        @include swap_direction(padding, 20px 40px);
        margin-bottom: 0;
        p {
          @include t1;
        }
        @include breakpoint($medium-down) {
          @include swap_direction(padding, 20px 10px);
        }
        .pr-snippet-stars {
          background-color: $color-lighter-gray;
        }
        &-display {
          dd {
            font-family: $font--avenir-heavy;
            font-weight: 400;
            @include t2;
          }
          dt {
            font-family: $font--avenir-roman;
            font-weight: normal;
            @include t2;
          }
        }
        .pr-snippet-stars-png {
          @include pr-background-image($pr-height-value, $pr-width-value);
        }
      }
      .pr-review-snapshot {
        width: 100%;
        justify-content: space-between;
        display: flex;
        flex-flow: column-reverse;
        @include breakpoint($medium-up) {
          flex-flow: row;
        }        
        &-header {
          order: 2;
          width: 100%;
          @include breakpoint($medium-up) {
            width: 40%;
            margin: 10px;
          }       
          .pr-snippet-stars-png {
            @include pr-background-image($pr-large-height-value, $pr-large-width-value);
          }
        }
        .pr-msq-list {
          font-family: $font--avenir-roman;
          dt {
            text-transform: uppercase;
            font-family: $font--avenir-heavy;
          }
        }
        .pr-review-snapshot-content-block {
          @include swap_direction(margin, 0);
          @include breakpoint($medium-up) {
            @include swap_direction(margin, 20px);
          }
        }
        a.pr-snippet-write-review-link {
          width: auto;
          padding: 13px 25px;
          background: $color-black;
          color: $color-white;
          border: 2px solid $color-black;
          text-transform: uppercase;
          display: inline-block;
          text-decoration: none;
          text-align: center;
          cursor: pointer;
          font-size: 19px;
          font-family: $font--avenir-heavy;
          transition: background 0.3s ease-in-out;
          position: absolute;
          bottom: 0;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
          margin-#{$ldirection}: auto;
          margin-#{$rdirection}: auto;
          width: 100%;   
          @include breakpoint($medium-up) {
            width: fit-content;   
          }
        }
        &-faceoff {
          display: none;
          .pr-faceoff-label,
          .pr-faceoff-title {
            font-family: $font--avenir-heavy;
            color: $color-black;
          }
          .pr-faceoff-review {
            font-family: $font--avenir-roman;
          }
          .pr-faceoff-title {
            text-transform: uppercase;
            @include h4;
            text-align: center;
          }
        }
      }
      .pr-review-snapshot-snippets {
        height: 100%;
        @include swap_direction(padding, 0 40px);
        @include breakpoint($medium-down) {
          @include swap_direction(padding, 0 10px);
          position: inherit;
          bottom: 110px;
          text-align: center;
          height: 220px;
        }
        .pr-snippet-stars-reco-inline {
          display: flex;
          justify-content: center;
          position: relative;
          height: 100%;
          .pr-snippet-stars-reco-reco {
            display: flex !important;
            width: 80%;
            #{$rdirection}: 0;
            margin-top: 40px;
            margin-#{$ldirection}: auto;
            margin-#{$rdirection}: auto;
            @include breakpoint($medium-up) {
              width: 100%;
              #{$rdirection}: auto;
              text-align: center;
            }
            .pr-snippet-reco-to-friend-percent {
              display: inline !important;
              > span {
                display: none !important;
              }
            }
            span {
              font-size: 15px;
              max-width: 70%;
              text-align: center;
              margin: 0 auto;
              line-height: 1.3;
              @include breakpoint($medium-up) {
                display: inline !important;
              }
            }
          }
        }
        .pr-snippet {
          display: flex;
          flex-flow: column;
          .pr-snippet-stars-container {
            .pr-snippet-stars {
              display: flex;
              flex-flow: column;
              .pr-rating-stars {
                margin-bottom: 10px;
              }
            }
            .pr-snippet-rating-decimal {
              font-size: 36px;
              color: $color-black;
              &:after {
                content: ' / 5';
              }
            }
          }
          .pr-snippet-read-and-write {
            display: flex;
            flex-flow: column;
            .pr-snippet-review-count {
              font-size: 14px !important;
              color: $color-grey;
            }
          }
        }
        .pr-snippet-stars-reco-reco {
          position: absolute;
          #{$ldirection}: 0;
          top: 80px;
          @include breakpoint($medium-up) {
            #{$rdirection}: 112px;
          }
        }
      }
      .pr-snippet-reco-to-friend {
        max-width: 100%;
        div,
        span {
          display: inline !important;
          margin-#{$rdirection}: 0;
          @include breakpoint($medium-down) {
            display: inline !important;
          }
        }
      }
    }
    .pr-review-snapshot {
      &-msq-container {
        display: none;
      }
      &-block {
        &-container {
          width: 100%;
          display: flex;
          margin-bottom: 0;
          flex-flow: column;
          @include breakpoint($medium-up) {
            flex-flow: row;
          }    
        }
        .pr-review-snapshot-block-headline {
          font-size: 19px;
          display: none;
        }
        .pr-snapshot-highlight {
          display: none;
        }
        .pr-snapshot-tag-def {
          text-align: center;
          color: $color-black;
          height: auto;
          @include breakpoint($medium-down) {
            text-align: #{$ldirection};
            display: inline-block;
          }
          &:first-of-type {
            padding-top: 0;
          }
          .pr-filter-btn {
            text-align: center;
            text-transform: initial;
          }
        }
      }
      &.pr-snapshot-mobile {
        .pr-review-snapshot-simple {
          .pr-reco-value {
            font-weight: bold;
          }
        }
      }
      .pr-review-snapshot-snippets-headline {
        color: $color-black;
      }
      .pr-review-snapshot-block {
        width: 100%;
        @include breakpoint($medium-up) {
          width: 50%;
        } 
        &:last-child {
          display: none;
        }
        &-cons {
          .pr-review-snapshot {
            &-tags {
              display: none;
            }
          }
        }
        &-pros {
          width: 100%;
          @include breakpoint($medium-up) {
            width: 45%;
          }
          .pr-review-snapshot {
            &-block-headline {
              display: block;
              background-color: $color-white;
              color: $color-black;
              @include h3;
              font-size: 19px;
              text-align: center;
            }
          }
          dl {
            display: flex;
            flex-flow: column;
          }
          dt {
            display: block;
            background-color: $color-white;
          }
        }
      }
    }
    .pr-review-display {
      .pr-rd-review-headline {
        color: $color-black;
        text-transform: lowercase;
        font-family: $font--avenir-heavy;
        font-size: 19px;
        @include swap_direction(padding, 4px 0 0 0);
        margin: 0;
        font-weight: 400;
        line-height: 1.31;
        &:first-letter {
          text-transform: uppercase;
        }
      }
      .pr-rd-main-header {
        .pr-review-filter-info-bar {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .pr-review-filter-clear-all {
            margin-bottom: 10px;
            color: $color-black;
          }
          .pr-filter-btn {
            background-color: transparent;
            border: 1px solid $color-black;
            text-transform: inherit;
            display: flex;
            flex-flow: row-reverse;
            float: #{$ldirection};
            &:hover {
              background-color: $color-white;
              .pr-cross-icon {
                background-color: $color-white;
              }
            }
            .pr-filter-btn__child {
              margin-#{$rdirection}: 10px;
            }
          }
        }
      }
      .pr-rd-main-footer {
        @include swap_direction(padding, 20px 40px 0 40px);
        border-bottom: none;
        font-family: $font--avenir-roman;
        text-align: center;
        text-transform: capitalize;
        @include breakpoint($medium-down) {
          @include swap_direction(padding, 20px 10px 0);
          background: $color-bg;
        }
        .pr-rd-review-position {
          float: #{$ldirection};
          @include swap_direction(margin, 0);
          @include breakpoint($medium-down) {
            float: none;
            margin-bottom: 10px;
          }
        }
        .pr-rd-pagination {
          float: #{$rdirection};
          @include swap_direction(padding, 0);
          margin-top: 0;
          @include breakpoint($medium-down) {
            float: none;
            @include swap_direction(margin, 5px 0);
            @include swap_direction(padding, 10px 0);
          }
        }
        .pr-rd-to-top {
          margin-#{$ldirection}: 35%;
          text-decoration: none;
          @include breakpoint($medium-down) {
            float: none;
            margin-#{$ldirection}: 0;
          }
        }
        .pr-rd-content-block {
          width: 100%;
        }
        .pr-rd-pagination-btn {
          color: $color-black;
          text-transform: capitalize;
        }
      }
      .pr-rd-footer {
        font-family: $font--avenir-roman;
        background-color: $color-lighter-gray;
        margin-bottom: 0;
        padding-bottom: 0;
        .pr-modal {
          @include breakpoint($medium-down) {
            margin-top: 30px;
          }
        }
        .pr-rd-bottomline {
          span {
            @include t1;
            &:first-child {
              display: none;
            }
            &:last-child {
              @include icon('checkbox--checked', before);
            }
          }
        }
        .pr-modal-visible {
          .pr-flag-review-btn {
            font-weight: 400;
            border-radius: 0;
          }
          .pr-flag-review-fieldset {
            input[type='radio'] {
              position: relative;
              #{$ldirection}: 0;
            }
          }
          .pr-flag-review-label {
            font-family: $font--avenir-heavy;
            font-weight: 400;
            b,
            abbr {
              font-weight: 400;
            }
          }
        }
        .pr-rd-helpful-text {
          font-size: 16px;
          font-weight: 400;
        }
        @include breakpoint($medium-down) {
          .pr-rd-flag-review-container {
            margin-top: 15px;
          }
        }
      }
      &.pr-rd-display-desktop {
        .pr-rd-star-rating {
          width: 100%;
        }
      }
    }
    .pr-thumbs-icon {
      display: none;
    }
    .pr-helpful {
      &-action {
        margin-#{$rdirection}: 10px;
      }
      &-count {
        color: $color-black;
        &:hover {
          color: $color-light-black;
        }
      }
      &-btn {
        border: 1px solid $color-black;
        @include swap_direction(padding, 5px);
        @include swap_direction(margin, 0 10px 0 0);
        background-color: $color-white;
        text-transform: capitalize;
      }
      &-yes {
        .pr-helpful-count {
          &:before {
            content: 'yes - ';
            visibility: visible;
          }
        }
      }
      &-no {
        .pr-helpful-count {
          &:before {
            content: 'no - ';
            visibility: visible;
          }
        }
      }
    }
  }
  .product-grid__content {
    .pr-snippet-stars-png {
      @include pr-background-image($pr-height-value, $pr-width-value);
    }
    .pr-snippet-stars-reco-inline {
      .pr-snippet {
        text-align: center;
      }
    }
  }
}
/* pwr alignment in Product Grid */
.product-grid__content {
  .pr-snippet-stars-png {
    @include pr-background-image($pr-height-value, $pr-width-value);
  }
  .pr-snippet-reco-to-friend {
    display: none;
  }
  .p-w-r {
    text-align: center;
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-stars-reco-reco,
        .pr-snippet-read-and-write {
          margin-top: 5px;
        }
      }
    }
  }
}
.write_a_review__container {
  #pr-write {
    .p-w-r {
      span,
      .form-group {
        font-family: $font--avenir-roman;
        label {
          &.pr-control-label {
            span {
              @include h4;
              font-family: $font--avenir-heavy;
              font-weight: 400;
            }
          }
        }
        .pr-helper-text {
          @include t1;
        }
        &.pr-media_image-form-group,
        &.pr-media_videourl-form-group {
          label {
            background-color: $color-black;
            span {
              color: $color-white;
              text-decoration: underline;
              &:after {
                color: $color-white;
              }
            }
          }
          .form-group {
            input {
              display: none;
            }
          }
          .pr-btn-delete {
            background-color: $color-black;
            border-color: $color-black;
            padding: 8px 12px;
            span {
              float: none;
            }
          }
          .pr-media-preview {
            .pr-loading-indicator {
              margin: 8%;
            }
          }
        }
      }
      .pr-subscript {
        font-family: $font--avenir-roman;
        @include t1;
        a {
          @include t1;
        }
      }
      .tag-group {
        .pr-label-control {
          &.checked {
            background-color: $color-black;
            span {
              color: $color-white;
            }
          }
        }
      }
      .pr-bottomline-form-group {
        .pr-btn {
          &.active {
            background-color: $color-black;
          }
        }
      }
      #pr-whatisyourhairtype {
        option {
          &:hover {
            background-color: $color-gray;
          }
        }
      }
      .pr-btn-add-tag {
        a {
          background-color: $color-black;
          color: $color-white;
          &:after {
            color: $color-white;
          }
        }
      }
    }
    .pr-header-title {
      @include h3;
      color: $color-black;
      font-weight: 400;
    }
    .thank-you-page {
      font-family: $font--avenir-roman;
      .title {
        @include h3;
        font-weight: 400;
      }
      .subtitle {
        color: $color-black;
        @include t1;
      }
      .header {
        a {
          @include t1;
        }
      }
      .review-preview {
        .headline {
          @include h4;
          text-transform: lower-case;
          &:first-letter {
            text-transform: uppercase;
          }
        }
        .pr-text {
          @include t2;
          &:first-child {
            @include t1;
            font-family: $font--avenir-roman;
          }
          .pr-dark {
            font-family: $font--avenir-roman;
            font-weight: normal;
          }
          span {
            font-family: $font--avenir-heavy;
            font-weight: 400;
          }
        }
      }
    }
  }
}
/* Hower functionality for start over products */
@if $show-reviews-on-hover {
  .product-brief__container {
    .p-w-r {
      @include swap_direction(margin, 0 0 10px);
    }
    @include breakpoint($medium-up) {
      .p-w-r {
        display: none !important;
      }
      position: relative;
      &:hover {
        .p-w-r {
          @include swap_direction(margin, 0);
          width: 30%;
          display: block !important;
          position: absolute;
          top: 10%;
          #{$ldirection}: 35%;
          .pr-snippet-stars {
            background-color: transparent;
          }
          .pr-snippet-stars-reco-inline {
            .pr-snippet-stars-reco-stars {
              float: none;
            }
          }
        }
      }
    }
  }
}

.shoppable-image {
  @include breakpoint($medium-down) {
    .p-w-r {
      @include swap_direction(margin, 0 0 10px);
      .pr-snippet {
        text-align: #{$ldirection};
      }
    }
  }
}

@if $show-reviews-on-hover {
  .mpp-container {
    @include breakpoint($medium-down) {
      .product-brief__container {
        .p-w-r {
          display: none !important;
        }
      }
    }
  }
}
/* Quick shop overlay for PWR Section */
$stars-size: 100px; // same as font size
$stars-height: 20px;

.recommended-item .product-rating,
.favorites__item .product-rating,
#cboxWrapper .product-quickshop .product-rating,
#cboxWrapper .product-full .product-rating {
  display: block;
  width: 100%;
  float: #{$ldirection};
  clear: both;
  margin-top: 5px;
}
.recommended-item .product-rating__stars-wrapper {
  position: relative;
  width: $stars-size;
}

#cboxWrapper .product-rating__stars-wrapper {
  position: relative;
  width: $stars-size;
  margin: 0;
}

.recommended-item,
.favorites__item,
.product-quickshop {
  .product-rating__stars {
    @include icon('stars', after);
    color: $color-black;
    font-size: $stars-size;
    height: 30px;
    line-height: $stars-height;
    overflow: hidden;
    text-align: #{$ldirection};
    width: $stars-size;
    float: #{$ldirection};
    &:after {
      line-height: 10px;
      vertical-align: top;
    }
    &--off {
      position: relative;
      color: $color-gray;
    }
    &--on {
      color: $color-black;
      #{$ldirection}: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }
  .product-rating {
    &__count {
      float: #{$ldirection};
      margin-#{$rdirection}: 5px;
      font-size: 12px;
      margin-#{$ldirection}: 8px;
      color: $color-gray;
    }
    a.read-reviews {
      margin-#{$rdirection}: 5px;
      float: #{$ldirection};
      &:after {
        content: '|';
        margin-#{$ldirection}: 5px;
      }
    }
    a.pc-only {
      font-size: 12px;
      color: $color-gray;
    }
    &__no-stars {
      a {
        padding-#{$ldirection}: 8px;
        float: #{$ldirection};
      }
    }
  }
}
/* Ask & Answer implementation */
.product-full {
  &__images {
    clear: both;
  }
  &__options {
    clear: both;
  }
  &__ratings {
    @include breakpoint($medium-up) {
      float: #{$ldirection};
    }
    float: none;
  }
}
.product-full__header {
  .product-full__ratings {
    display: initial;
    #pr-questionsnippet,
    .product-full__review-snippet {
      display: block;
      vertical-align: middle;
      .p-w-r {
        .pr-snippet-qa {
          @include swap_direction(padding, 10px 0 0);
          vertical-align: middle;
        }
      }
    }
  }
}
#pr-questionsnippet {
  .p-w-r {
    @include swap_direction(margin, 0);
    .pr-snippet-qa {
      margin-top: 5px;
      @include swap_direction(padding, 10px 15px 0);
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 0);
      }
      .pr-ask-question-link {
        color: $color-gray;
        margin-top: 0;
        font-family: $font--avenir-light;
        border-#{$ldirection}: 1px solid $color-gray;
        @include swap_direction(padding, 0 8px);
        display: inline-block;
        &:hover {
          text-decoration: unset;
          color: $color-gray;
        }
      }
      .pr-ask-question-cta,
      .pr-ask-question-subtext {
        display: none;
      }
      .pr-snippet-qa-metrics {
        .pr-qa-large {
          display: none;
        }
        .pr-answered-question-circle {
          display: none;
        }
        .pr-snippet-qa-answer-link {
          display: inline-block;
          font-family: $font--avenir-book;
          color: $color-gray;
          font-weight: 300;
          border-#{$ldirection}: 1px solid $color-gray;
          padding-#{$ldirection}: 10px;
        }
      }
    }
  }
}

.spp_reviews {
  ul {
    &.tabs-nav {
      @include swap_direction(padding, 0);
      display: block;
      @include breakpoint($medium-up) {
        display: flex;
        justify-content: center;
      }
      &.stick {
        position: fixed;
        top: 0;
        width: 100%;
        background: $color-white;
        z-index: 9999;
      }
      li {
        @include icon('form_dropdown-arrow', after);
        font-family: $font--avenir-heavy;
        font-size: 16px;
        position: relative;
        @include swap_direction(padding, 15px 0);
        color: $color-lighted-gray;
        border-bottom: 1px solid $color-lighted-gray;
        cursor: pointer;
        @include breakpoint($medium-up) {
          margin: 0 10px;
          font-size: 40px;
          border-bottom: 0;
          text-align: center;
        }
        &:nth-child(1) {
          @include breakpoint($medium-up) {
            border-top: 0;
          }
          border-top: 1px solid $color-lighted-gray;
        }
        &:after {
          @include breakpoint($medium-up) {
            display: none;
          }
          position: absolute;
          top: 19px;
          #{$rdirection}: 11px;
          font-size: 12px;
          transition: transform 0.333s ease;
          transform: rotateZ(180deg);
        }
        &.tab-active {
          color: $color-black;
          text-decoration: underline;
          &:after {
            @include breakpoint($medium-up) {
              display: none;
            }
            position: absolute;
            transform: rotateZ(0deg);
            font-size: 12px;
            top: 19px;
            #{$rdirection}: 11px;
          }
        }
      }
    }
  }
}
#pr-questiondisplay {
  display: none;
  @include breakpoint($medium-up) {
    @include swap_direction(padding, 0 0 30px);
  }
  @include swap_direction(padding, 30px 0);
  @include swap_direction(margin, 0);
  .p-w-r {
    &:focus {
      outline: none;
      box-shadow: none;
    }
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 0);
    .pr-btn-ask-question,
    .pr-btn-answer {
      &:focus {
        .pr-qa-display-text {
          text-shadow: none;
          background-image: none;
        }
      }
    }
    .pr-waqwaa {
      .pr-helper-text,
      .pr-waq-legal-text {
        font-family: $font--avenir-medium;
        padding-bottom: 5px;
        line-height: 20px;
        color: $color-black;
        font-size: $base-font-size;
      }
      .pr-waq-legal-text {
        span {
          color: $color-black;
          a {
            color: $color-black;
            text-transform: capitalize;
          }
        }
      }
      .form-group {
        @include breakpoint($medium-up) {
          margin-bottom: 15px;
        }
        margin-bottom: 25px;
        label {
          &.pr-control-label {
            font-family: $font--avenir-medium;
            font-weight: normal;
            span {
              color: $color-black;
              font-size: 16px;
            }
          }
        }
        &.pr-waq-legal-text {
          margin-bottom: 10px;
        }
        #questionCategory {
          .btn-group {
            @include breakpoint($medium-up) {
              width: 60%;
              display: block;
            }
            .pr-btn {
              @include breakpoint($medium-up) {
                margin-bottom: 8px;
                border: 1px solid $color-lighted-gray;
              }
              &:nth-child(1) {
                border-top: 1px solid $color-lighted-gray;
              }
              margin-bottom: 0;
              border-top: 0;
              font-family: $font--avenir-medium;
              border-radius: 0;
              color: $color-black;
              &.active {
                background-color: $color-black;
                color: $color-white;
              }
            }
          }
        }
        .form-control {
          background: $color-white;
          border-radius: 0;
          border-color: $color-lighted-gray;
          box-shadow: none;
          font-family: $font--avenir-medium;
          &::placeholder {
            font-family: $font--avenir-medium;
            color: $color-black;
          }
        }
        &.pr-has-error {
          .form-control {
            border-color: $color-red-error;
            &::placeholder {
              color: $color-red-error;
            }
          }
          label {
            span {
              color: $color-red-error;
            }
          }
        }
      }
    }
    .pr-qa-display-ask-question {
      margin-bottom: 0;
      @include breakpoint($medium-up) {
        float: none;
        @include swap_direction(padding, 0);
        overflow: hidden;
      }
      .p-w-r {
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 0);
          border-bottom: 1px solid $color-lighted-gray;
          clear: both;
          margin-bottom: 40px;
        }
        @include swap_direction(padding, 0);
        .pr-waqwaa {
          margin: 0 auto;
        }
      }
      .pr-accessible-btn,
      button {
        @include breakpoint($medium-up) {
          width: auto !important;
          float: #{$rdirection};
        }
        background-color: $color-black;
        @include swap_direction(padding, 10px 40px);
        font: 13px/16px normal;
        width: 100% !important;
        font-family: $font--avenir-roman;
        text-transform: uppercase;
        @include swap_direction(margin, 0 0 30px 0);
        border: 0;
        &:hover {
          color: $color-white;
        }
        .pr-cross-icon {
          display: none;
        }
        .pr-qa-display-text {
          color: $color-white;
          font-size: 15px;
        }
      }
      #pr-question-form-link {
        color: $color-white;
      }
    }
    .pr-qa-display {
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 0);
      }
      background: $color-bg-gray;
      @include swap_direction(padding, 0 15px);
      .pr-qa-display-headline {
        @include breakpoint($medium-up) {
          display: block;
          position: relative;
          @include swap_direction(padding, 40px 40px 0px);
        }
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 25px 0 0);
        .pr-qa-display-search {
          @include breakpoint($medium-up) {
            overflow: hidden;
            margin-bottom: 30px;
          }
          width: 100%;
          background-color: $color-bg-gray;
          padding: 0;
          margin-bottom: 15px;
          form {
            @include breakpoint($medium-up) {
              float: #{$rdirection};
            }
            label {
              @include breakpoint($medium-up) {
                @include swap_direction(padding, 0);
                @include swap_direction(margin, 0);
                width: auto !important;
                text-align: #{$ldirection};
              }
              font-family: $font--avenir-black;
              font-size: 24px;
              color: $color-black;
              display: inline-block;
              width: 100% !important;
              text-align: center;
              padding: 0 25px;
              margin-bottom: 20px;
            }
            .pr-qa-display-searchbar {
              @include breakpoint($medium-up) {
                display: table-cell;
              }
              display: initial;
              .pr-qa-search-btn {
                background-color: $color-black;
                border-radius: 0;
                span {
                  path {
                    fill: $color-white;
                  }
                }
              }
              &-input {
                background-color: $color-white;
                border-radius: 0;
                border: 1px solid $color-black;
                @include swap_direction(padding, 0 15px);
                color: $color-black;
                font-family: $font--avenir-roman;
                &::placeholder {
                  color: $color-black !important;
                }
                &.custom-outline {
                  outline: 0;
                }
                &::placeholder {
                  color: $color-white;
                }
              }
            }
          }
          .pr-qa-display-clear-search-button {
            font-family: $font--avenir-roman;
            font-size: $base-font-size;
            color: $color-black;
          }
          .pr-qa-display-search-no-results {
            @include swap_direction(padding, 30px 0);
            font-family: $font--avenir-roman;
            font-size: 16px;
            h2 {
              font-family: $font--avenir-black;
              color: $color-black;
            }
          }
        }
        .pr-qa-display-sort {
          @include swap_direction(padding, 0 0 25px 0);
          width: 100%;
          .pr-qa-sort-headline {
            @include breakpoint($medium-up) {
              float: #{$ldirection};
              margin-bottom: 8px;
            }
            font-family: $font--avenir-medium;
            font-weight: 400;
            text-align: center;
            display: block;
            float: none;
            margin-bottom: 30px;
            color: $color-black;
          }
          .pr-qa-sort-group {
            @include breakpoint($medium-up) {
              width: auto;
            }
            width: 100%;
            label {
              @include breakpoint($medium-up) {
                margin-bottom: 0px;
              }
              font-family: $font--avenir-black;
              text-transform: uppercase;
              font-size: 15px;
              color: $color-black;
              margin-bottom: 10px;
              display: inline-block;
              &:after {
                content: ':';
              }
            }
            select {
              content: '\E007';
              &::-ms-expand {
                display: none;
              }
            }
            .pr-qa-sort {
              @include breakpoint($medium-up) {
                width: auto;
              }
              border: 1px solid $color-black;
              font-family: $font--avenir-medium;
              font-size: $base-font-size;
              cursor: pointer;
              width: 100%;
              max-width: 100%;
              -webkit-appearance: none;
              -moz-appearance: none;
              @include swap_direction(padding, 0 25px);
              border-radius: 5px;
            }
            &:after,
            &:before {
              border-#{$ldirection}: 5px solid transparent;
              border-#{$rdirection}: 5px solid transparent;
              position: absolute;
              content: '';
              #{$rdirection}: 13px;
            }
            &:after {
              @include breakpoint($medium-up) {
                top: 17px;
              }
              border-top: 5px solid $color-black;
              top: 43px;
            }
            &:before {
              @include breakpoint($medium-up) {
                top: 9px;
              }
              border-bottom: 5px solid $color-black;
              top: 35px;
            }
          }
        }
        h1 {
          display: none;
        }
      }
      .pr-qa-display-question {
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 0 40px);
        }
        width: 100%;
        float: unset;
        clear: both;
        &list {
          overflow: hidden;
          padding-bottom: 25px;
          border-#{$ldirection}: unset;
          @include swap_direction(padding, 0);
          @include swap_direction(margin, 25px 0 0 0);
          .pr-qa-display-item {
            @include breakpoint($medium-up) {
              margin-bottom: 30px;
              width: 100%;
            }
            float: #{$ldirection};
            margin-bottom: 10px;
            .pr-qa-display-item-icon {
              background-color: $color-black;
              font-size: 16px;
              padding-top: 0;
              width: 35px;
              height: 35px;
              line-height: 31px;
              #{$ldirection}: 0;
              top: 0;
            }
            .pr-qa-display-item-text {
              @include swap_direction(padding, 8px 0 0 45px);
              font-family: $font--avenir-black;
              font-size: 16px;
              color: $color-black;
            }
          }
          .pr-qa-display-item-details {
            @include breakpoint($medium-up) {
              padding-#{$ldirection}: 5px;
              width: 22%;
              top: 60px;
              float: #{$rdirection};
              position: relative;
              #{$ldirection}: auto;
            }
            position: unset;
            width: 100%;
            .pr-subscript,
            time {
              font-size: $base-font-size;
              color: $color-black;
              display: block;
              font-family: $font--avenir-medium;
              font-weight: bold;
              line-height: 24px;
            }
          }
          .pr-qa-display-answer-link-container {
            @include swap_direction(margin, 0 0 25px 0);
            .pr-btn-answer {
              @include breakpoint($medium-up) {
                width: auto !important;
              }
              @include swap_direction(padding, 10px 40px);
              background: $color-black;
              text-transform: uppercase;
              font-family: $font--avenir-medium;
              margin-bottom: 0;
              width: 100% !important;
              span {
                color: $color-white;
                &.pr-cross-icon {
                  display: none;
                }
              }
            }
          }
          .pr-qa-display-add-answer {
            @include breakpoint($medium-up) {
              clear: both;
              width: 60%;
              float: #{$ldirection};
            }
            margin-#{$ldirection}: 0px;
            .p-w-r {
              .pr-waqwaa {
                max-width: 100%;
                .form-group {
                  margin-bottom: 20px;
                }
                .pr-answer-form {
                  .form-control {
                    &.custom-outline {
                      outline: unset;
                    }
                  }
                  .pr-submit {
                    @include breakpoint($medium-up) {
                      margin-bottom: 15px;
                    }
                    .pr-accessible-btn {
                      background: $color-black;
                      border: 0px;
                      border-radius: 0px;
                      @include swap_direction(padding, 10px 75px);
                      text-transform: uppercase;
                      font: 15px/16px normal;
                      font-family: $font--avenir-medium;
                      color: $color-white;
                      &:hover {
                        color: $color-white;
                      }
                    }
                  }
                }
              }
            }
          }
          .pr-qa-display-answer {
            @include breakpoint($medium-up) {
              display: inline-block;
              width: 75%;
              float: #{$ldirection};
            }
            border-bottom: 1px solid $color-lighted-gray;
            margin: 0;
            &:nth-last-child(2n) {
              @include swap_direction(padding, 0 0 25px);
              position: relative;
              top: -1px;
              border-top: 1px solid $color-bg-gray;
            }
            &-by-container {
              padding-#{$ldirection}: 0;
              &-unverified {
                padding-#{$ldirection}: 3px;
              }
              &-expert {
                border-#{$ldirection}: 0px;
              }
            }
            &-details {
              font-family: $font--avenir-medium;
              @include swap_direction(padding, 0 0 0 2px);
              &:focus {
                outline: none;
                box-shadow: none;
              }
              span {
                color: $color-black;
                font-size: $base-font-size;
              }
              .pr-helpful-voting {
                @include swap_direction(padding, 20px 0 20px);
                .pr-helpful-btn {
                  background-color: $color-white;
                  @include swap_direction(padding, 2px 10px);
                  margin-#{$rdirection}: 15px;
                  border: 0;
                  border-radius: 0px;
                  &.pr-helpful-yes {
                    border-#{$rdirection}: 1px solid $color-black;
                  }
                  &.pr-helpful-active {
                    background: $color-black !important;
                    .pr-thumbs-icon {
                      path {
                        fill: $color-white;
                      }
                    }
                    .pr-helpful-count {
                      color: $color-white;
                    }
                  }
                  .pr-thumbs-icon {
                    path {
                      fill: $color-black;
                    }
                  }
                  .pr-helpful-count {
                    color: $color-black;
                    line-height: 24px;
                  }
                }
              }
            }
            &-icon {
              display: none;
            }
          }
        }
      }
    }
    .pr-qa-display-thankyou-headline {
      @include swap_direction(margin, 0 0 50px);
      @include swap_direction(padding, 25px);
      font-family: $font--avenir-light;
      font-size: 26px;
      text-align: center;
      float: none;
    }
  }
  .add-your-answer-button--hide {
    .pr-qa-display-answer-link-container {
      display: none;
    }
  }
}
.write_a_review__container {
  #pr-write {
    .p-w-r {
      .pr-btn.active {
        background-color: $color-black;
      }
      .table-cell {
        @include swap_direction(padding, 10px 0);
      }
      .pr-submit {
        .pr-accessible-btn {
          @include swap_direction(padding, 10px 40px);
          background-color: $color-black;
          font: 13px/16px normal;
          font-family: $font--avenir-medium;
          text-transform: uppercase;
          border: 0;
          &:hover {
            color: $color-white;
          }
        }
      }
    }
  }
}

#pr-write .pr-email_collection-form-group {
  // Hide email field from write a review.
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}
