///
/// @file template_api/_gnav-util.scss
///
/// \brief Styles for gnav utility links and expandable content wrappers
///
///
///
.gnav-util--locator {
  display: block;
  @include breakpoint($medium-up) {
    display: none;
  }
}

.gnav-util-formatter {
  @include breakpoint($medium-up) {
    float: #{$rdirection};
  }
}

.gnav-util-formatter__item {
  float: #{$ldirection};
}

.gnav-util__link {
  display: inline-block;
  padding: 0 1em;
}

.gnav-util__icon {
  display: block;
  color: $color-white;
  line-height: $header-height--mobile;
  position: relative;
  text-decoration: none;
  @include breakpoint($iphone6-up) {
    line-height: $header-height-large--mobile;
  }
  @include breakpoint($medium-up) {
    line-height: $header-main-height;
  }
  .icon {
    padding-bottom: 5px;
  }
  &:hover {
    color: $color-banner-gray;
    text-decoration: none;
  }
}

.gnav-util__content {
  display: none;
  //@include transition(opacity 0.4s ease-out);
  color: $color-black;
  position: absolute;
  //top: $header-height--mobile + 1;
  background: $color-white;
  width: 100%;
  z-index: 10;
  #{$ldirection}: 0;
  //opacity: 1;
  @include breakpoint($landscape-up) {
    //top: $header-height + 1;
    border-top: 1px solid $color-white;
  }
  &.gnav-util__content--cart {
    //display: block !important;
  }
  &-close {
    position: absolute;
    z-index: 10;
    #{$rdirection}: 20px;
    top: 20px;
    line-height: 20px;
    text-decoration: none;
    &:hover,
    &:active {
      text-decoration: none;
    }
    .icon {
      font-size: 20px;
    }
  }
  &-inner {
    padding: 20px 15px;
    @include breakpoint($landscape-up) {
      top: $header-height;
      padding: 30px 20px;
    }
    .gnav-util__content--search & {
      //padding: 45px 0 0 0
      padding: 0;
      min-height: 135px;
      @include breakpoint($landscape-up) {
        //padding: 62px 0 45px 0;
        padding: 0;
      }
    }
    .gnav-util__content--account & {
      padding: 15px 20px 20px 20px;
      @include breakpoint($landscape-up) {
        padding: 15px 20px 20px 20px;
      }
    }
  }
}

.gnav-util__content {
  width: 100%;
  position: absolute;
  top: $header-height--mobile;
  #{$ldirection}: 0;
  z-index: 1;
  background: $color-white;
  color: $color-black;
  @include breakpoint($iphone6-up) {
    top: $header-height-overlay--mobile;
  }
  @include breakpoint($medium-up) {
    top: $header-main-height;
  }
  .gnav-util--account & {
    position: absolute;
    width: 100%;
    #{$ldirection}: 0;
    background: $color-lighter-gray;
    @include breakpoint($medium-up) {
      width: 340px;
      #{$ldirection}: auto;
      #{$rdirection}: -41px;
    }
  }
}

.gnav-util__content-inner {
  @include min-width($min-width);
  max-width: $max-width-large;
  margin: 0 auto;
}

// gnav locator subnav
.gnav__locator {
  position: relative;
  display: none;
}

.gnav-util__content-close,
.gnav__locator__close {
  display: block;
  position: absolute;
  top: 5px;
  #{$rdirection}: 10px;
  font-size: 20px;
  line-height: 20px;
  text-decoration: none;
  z-index: 10;
  &:hover {
    text-decoration: none;
  }
  @include breakpoint($medium-up) {
    top: 20px;
    #{$rdirection}: 20px;
  }
}

.gnav__locator__subnav {
  @include min-width($min-width);
  max-width: $max-width-large;
  margin: 0 auto;
  color: $color-black;
}

.gnav__locator__wrapper {
  padding: 40px 10px 38px 10px;
}

.gnav__locator__search__container {
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  .icon {
    display: block;
    position: absolute;
    top: 0;
    #{$rdirection}: 0;
    width: 50px;
    height: 50px;
    color: $color-white;
    line-height: 50px;
    font-size: 30px;
    background: $color-black;
  }
}

input[type='text'].gnav__locator__search {
  height: 50px;
  line-height: 48px;
  padding-#{$rdirection}: 52px;
  width: 100%;
  text-align: center;
}

input[type='submit'].gnav__locator__search__submit {
  @include squish-text;
  position: absolute;
  top: 0;
  #{$rdirection}: 0;
  width: 50px;
  height: 50px;
  opacity: 0;
  z-index: 2;
}

.gnav__locator__search__international {
  text-align: center;
  margin-top: 20px;
}

.gnav__locator__search__international__link {
  @include link;
}

.gnav-util__carrot {
  #{$rdirection}: 0;
  @include breakpoint($ipad-mini-portrait) {
    display: none;
  }
}

// CART Overlay
.gnav-util__content--cart {
  #{$ldirection}: auto;
  #{$rdirection}: 0;
  max-height: 84vh;
  -webkit-overflow-scrolling: touch;
  background-color: $color-white;
  text-transform: uppercase;
  max-width: 400px;
  @include breakpoint($landscape-up) {
    max-width: none;
    width: 350px;
  }
  @include breakpoint($medium-only) {
    width: 350px;
  }
  .gnav-util__content-edit {
    float: $ldirection;
    position: relative;
    top: 20px;
    #{$ldirection}: 20px;
    border-bottom: solid 1px $color-black;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  .gnav-util__content-close {
    top: 1.2em;
    @include breakpoint($medium-up) {
      top: 0.5em;
    }
  }
}
// cart
.gnav-util--cart {
  padding-#{$ldirection}: 20px;
  @include breakpoint($medium-up) {
    display: block;
    position: relative;
    width: 20px;
    html.pro-header .hide-cart-icon & {
      width: 0px;
    }
  }
  .gnav-util__icon--cart {
    position: absolute;
    width: 20px;
    #{$rdirection}: 10px;
    @include breakpoint($medium-up) {
      position: relative;
      #{$rdirection}: unset;
    }
    &.active {
      .gnav-util__carrot {
        opacity: 1;
      }
    }
    .gnav-util__carrot {
      position: absolute;
      bottom: -1px;
      @include breakpoint($medium-up) {
        bottom: 0;
      }
      opacity: 0;
      @include transition(opacity 0.35s ease-in-out);
    }

    // we want the inline cart to popup during add-to-bag
    // but not the icon
    html.pro-header .hide-cart-icon & {
      display: none;
    }
    .gnav-util__icon__cart-count {
      display: block;
      position: absolute;
      line-height: 18px;
      text-align: center;
      width: 100%;
      margin-top: 12px;
      font-size: 8px;
      @include breakpoint($iphone6-up) {
        margin-top: 17px;
        font-size: 10px;
      }
      @include breakpoint($medium-up) {
        margin-top: 26px;
      }
    }
    .icon--bag {
      font-size: 20px;
      @include breakpoint($iphone6-up) {
        font-size: 22px;
      }
    }
  }
}

// account
.gnav-util--account {
  display: none;
  &.network-gnav-account {
    display: block;
  }
  @include breakpoint($medium-up) {
    display: block;
    position: relative;
    width: 20px;
    margin-#{$rdirection}: 20px;
    html.pro-header .hide-cart-icon & {
      margin-#{$rdirection}: 0px;
    }
  }
  .icon--utility_outlined_my-account {
    font-size: 18px;
    @include breakpoint($iphone6-up) {
      font-size: 22px;
    }
  }
  .gnav-util__icon--account {
    @include breakpoint($medium-up) {
      &.active {
        .gnav-util__carrot {
          opacity: 1;
        }
      }
    }
    .gnav-util__carrot {
      position: absolute;
      bottom: 0;
      @include breakpoint($medium-up) {
        right: 0;
      }
      opacity: 0;
      @include transition(opacity 0.35s ease-in-out);
    }
  }
}

// search
.gnav-util--search {
  width: 20px;
  margin-#{$rdirection}: 6px;
  @include breakpoint($iphone6-up) {
    margin-#{$rdirection}: 10px;
  }
  @include breakpoint($medium-up) {
    margin-#{$rdirection}: 20px;
  }
  .gnav-util__content--search {
    top: 54px;
    @include breakpoint($medium-up) {
      top: 65px;
    }
  }
  .gnav-util__icon--search {
    position: relative;
    &.active {
      .gnav-util__carrot {
        opacity: 1;
        #{$rdirection}: 0;
      }
    }
    .icon--search {
      font-size: 18px;
      @include breakpoint($iphone6-up) {
        font-size: 22px;
      }
    }
    .gnav-util__carrot {
      position: absolute;
      bottom: 0;
      opacity: 0;
      @include transition(opacity 0.35s ease-in-out);
    }
  }
}
