.join-network {
  input[type='radio'],
  input[type='checkbox'] {
    position: static;
  }
  label {
    font-weight: bold;
  }
  .error_message {
    color: $color-error;
    margin-bottom: 20px;
  }
  .main_header {
    font-size: 24px;
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      font-size: 32px;
    }
  }
  .step1_fields {
    &_wrap {
      display: flex;
      flex-wrap: wrap;
      padding-top: 15px;
      border-top: 1px solid $color-black;
      .form_element {
        flex-grow: 1;
        width: 50%;
        margin-bottom: 20px;
        padding: 0 2px;
        @include breakpoint($medium-up) {
          width: 33%;
          padding: 0 5px;
        }
      }
      select {
        // Override inline CSS
        display: block !important;
        width: 100% !important;
        height: 45px !important;
      }
      input[type='text'] {
        display: block;
        width: 100%;
      }
    }
  }
  fieldset,
  .step1_fields_wrap {
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid $color-black;
  }
  .step1_survey {
    li > label,
    li > div {
      display: inline-block;
    }
    label {
      margin-#{$rdirection}: 10px;
    }
    ul,
    .survey_panels {
      margin-bottom: 20px;
    }
  }
  .checkbox_option,
  .radio_option {
    display: inline-block;
    margin-#{$rdirection}: 10px;
    margin-#{$ldirection}: 3px;
  }
  .list_small_font {
    display: block;
  }
  textarea {
    display: block;
    width: 100%;
  }

  // Hare Skin Makeup lists
  .lines_option {
    &_trigger {
      font-weight: bold;
      color: $color-black;
      cursor: pointer;
      &:before {
        content: '+ ';
        display: inline-block;
        width: 20px;
      }
      &.active:before {
        content: '- ';
      }
    }
    ul {
      display: none;
      margin: 10px 0 20px;
    }
  }
  .continue_button {
    margin-top: 10px;
    text-align: #{$rdirection};
  }
  #form--step_one--field--contact_type_other,
  #form--step_one--field--lease_commission_other {
    display: none;
  }
}
