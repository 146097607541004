.network {
  margin: 30px auto 0;
  max-width: $max-width;
  /* Breadcrumbs  */
  &-breadcrumbs {
    text-align: right;
    font-size: 12px;
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      margin-bottom: 30px;
    }
    .step {
      display: none;
      border-bottom: 1px solid $color-gray;
      padding-bottom: 20px;
      padding-right: 20px;
      @include breakpoint($medium-up) {
        border: none;
        padding: 0;
      }
      span.brief-step {
        display: none;
        @include breakpoint($medium-up) {
          display: inline;
        }
      }
      span {
        display: inline;
      }
    }
  }
  /* Registration Section  */
  &-registration {
    text-align: center;
    width: 100%;
    padding: 20px 5px;
    @include breakpoint($medium-up) {
      margin: 0 auto 30px auto;
      width: 800px;
      padding: 0;
    }
    &__country-selector {
      width: 100%;
      margin-bottom: 30px;
      @include breakpoint($medium-up) {
        float: left;
        margin-right: 4%;
        width: 48%;
      }
    }
    &__continue {
      width: 100%;
    }
    &__continue-without-salon {
      position: relative;
      &::before {
        content: '';
        display: block;
        border-top: 1px solid $color-border;
        margin-bottom: 30px;
        margin-top: 20px;
        @include breakpoint($medium-up) {
          width: 720px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 30px;
          margin-top: 30px;
        }
      }
      &__text {
        font-weight: bold;
        margin-bottom: 15px;
        font-size: 18px;
      }
      &__button {
        width: 100%;
        @include breakpoint($medium-up) {
          width: auto;
        }
      }
    }
    &__state-selector {
      width: 100%;
      margin-bottom: 30px;
      @include breakpoint($medium-up) {
        float: left;
        width: 48%;
      }
    }
    &__zipcode-selector {
      clear: left;
      display: inline-block;
      position: relative;
      width: 100%;
      input {
        width: 100%;
      }
      margin-bottom: 30px;
      @include breakpoint($medium-up) {
        width: auto;
        margin-bottom: 0;
      }
    }
    &__divider {
      margin-bottom: 20px;
      @include breakpoint($medium-up) {
        margin-bottom: 30px;
      }
    }
    &__zip-search-submit {
      bottom: -1px;
      font-size: 26px;
      padding: 2px 8px;
      position: absolute;
      right: 0;
      @include breakpoint($medium-up) {
        right: -40px;
      }
      top: 0;
    }
    &__selector-title {
      font-weight: bold;
      margin-bottom: 20px;
      font-size: 18px;
    }
    &__selector-fields {
      @include breakpoint($medium-up) {
        margin-bottom: 30px;
      }
    }
    &__title {
      font-size: 35px;
      line-height: 35px;
      font-weight: bold;
      margin-bottom: 40px;
      @include breakpoint($medium-up) {
        font-size: 40px;
        line-height: 30px;
        margin-bottom: 25px;
      }
    }
    &__description {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 22px;
      @include breakpoint($medium-up) {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 30px;
      }
    }
    &__selector-fields {
      margin: 0;
      @include breakpoint($medium-up) {
        margin: 0 40px 30px;
      }
    }
    /* Salon List */
    &__salon--list {
      border: 2px solid $color-black;
      height: 220px;
      margin: 0 auto 30px auto;
      width: 100%;
      @include breakpoint($medium-up) {
        border: 1px solid $color-black;
        width: 720px;
        height: 120px;
        padding: 0 20px;
      }
      overflow-y: scroll;
      .salon-option {
        float: left;
        margin: 0;
        position: relative;
        width: 100%;
        border-bottom: 1px solid $color-gray;
        padding: 17px 15px 20px;
        @include breakpoint($medium-up) {
          width: 33%;
          padding: 0;
          border: none;
          margin: 15px 0;
          &:nth-child(3n + 1) {
            clear: left;
          }
        }
        label {
          position: relative;
          width: 100%;
          padding: 0 20px;
          text-align: left;
          font-size: 14px;
        }
        input {
          display: inline-block;
        }
        input[type='radio'] ~ label::before {
          left: -5px;
          position: absolute;
          top: 50%;
          transform: translate(0px, -50%);
        }
      }
    }
    /* Registration Form Section */
    &__prosection {
      display: none;
      padding: 20px;
      @include breakpoint($medium-up) {
        padding: 0;
      }
      .user-form-submit {
        width: 100%;
        @include breakpoint($medium-up) {
          width: auto;
        }
      }
      .password-message {
        margin-top: 5px;
      }
      .profile-info__sub--header {
        margin-bottom: 30px;
      }
      .error_messages {
        margin-bottom: 30px;
      }
      .profile-info__item {
        float: left;
        margin-bottom: 25px;
        width: 100%;
        @include breakpoint($medium-up) {
          width: 48%;
          margin-right: 2%;
          margin-bottom: 35px;
        }
        input {
          width: 100%;
        }
        &--small {
          @include breakpoint($medium-up) {
            width: 31.3%;
            margin-right: 2%;
          }
        }
        a.selectBox {
          height: 44px;
          width: 90%;
          border: none;
        }
        .profile-info {
          @include breakpoint($medium-up) {
            padding-left: 3%;
          }
        }
      }
      .error_messages li {
        color: $color-red;
      }
    }
    &__success--section {
      display: none;
    }
    &__success--message {
      font-size: 25px;
      margin-bottom: 20px;
    }
  }
  &-selector--section {
    margin: 0 auto;
    width: 80%;
    @include breakpoint($medium-up) {
      width: 100%;
      button {
        width: auto;
      }
    }
  }
  label {
    text-align: left;
  }
  .selectBox-label::after {
    background: $color-black;
    color: $color-white;
  }
  select {
    width: 100%;
    height: 44px;
  }
}
