@mixin loyalty-how-to-earn {
  .loyalty__panel {
    &__how-to-earn {
      @include loyalty-panel;
      &.is-member {
        display: none;
        .loyalty__page__about & {
          display: block;
        }
      }
      &__title {
        @include loyalty-panel-title;
      }
    }
  }
} // @mixin loyalty-how-to-earn
