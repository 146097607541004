@mixin loyalty-clear {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin loyalty-panel {
  @include loyalty-clear;
  @include swap_direction(padding, 10px);
}

@mixin loyalty-panel-title {
  text-align: #{$ldirection};
  border-bottom: 1px solid $color-black;
  margin-bottom: 20px;
  font-size: 19px;
  @include breakpoint($medium-up) {
    text-transform: none;
    font-size: 41px;
    line-height: 1.5em;
    text-align: center;
    margin-bottom: 30px;
  }
}

@mixin loyalty-panel-title-pc {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
  }
}

@mixin loyalty-panel-title-mobile {
  display: block;
  @include breakpoint($medium-up) {
    display: none;
  }
}

@mixin loyalty-panel-subtitle {
  text-align: center;
  font-size: 16px;
  font-family: $font--avenir-book;
  margin-bottom: 30px;
}
