@mixin loyalty-leave {
  .loyalty__panel__leave {
    @include loyalty-panel;
    display: none;
    clear: both;
    &.is-member {
      display: block;
    }
  }
  .loyalty-signout {
    margin-top: 30px;
    &__form,
    &__sorry {
      &__container {
        .loyalty {
          &__signout {
            &-title {
              font-weight: bold;
              text-align: center;
              text-transform: uppercase;
            }
            &__cancel__results,
            &__contact__us {
              margin: 10px 0;
            }
            &__submit__buttons {
              text-align: $rdirection;
              margin: 15px 0 0;
            }
          }
          &__active__flag__container {
            .disenrol__checked {
              position: static;
              margin: 0 5px 15px 0;
            }
          }
        }
        .error_messages {
          li {
            color: $color-red;
            margin-bottom: 20px;
          }
        }
      }
      &__fieldset {
        padding: 30px 10px;
      }
    }
  }
} // @mixin loyalty-leave
