.subnav__menu__collections {
  position: relative;
}

.subnav__menu__collections {
  .subnav__menu__formatter__item {
    &:last-child {
      @include breakpoint($medium-up) {
        width: 80%;
        left: 20%;
        position: absolute;
        bottom: 0px;
      }
    }
  }
}

.subnav__collections__carousel {
  .subnav__carousel__image {
    height: 300px;
  }
}

.subnav__carousel__formatter__items {
  visibility: hidden;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spinner 0.6s linear infinite;
}
