.gc_ecard_balance {
  ul#gc-bal-info {
    margin: 10px 0;
    padding-left: 18px;
    li {
      list-style: disc outside none;
      padding: 5px 0;
    }
  }
  .form_element {
    margin: 0 20px 20px 0;
    float: left;
    @include breakpoint($small-down) {
      margin: 10px 0;
      float: none;
    }
  }
  div.form_element input {
    display: block;
    margin-top: 5px;
    @include breakpoint($small-down) {
      width: 100%;
    }
  }
  .gc_chkbalance_btn {
    input.form-submit {
      width: auto;
    }
  }
}

.nav_giftcards {
  ul {
    line-height: 2em;
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
  }
}

.egiftcard_sidebar {
  float: left;
  width: 20%;
  .sidebar_title {
    @include header--small;
  }
  @include breakpoint($small-down) {
    display: none;
  }
}

.egiftcard {
  position: relative;
  padding: 0 20px;
  margin: 30px auto;
  max-width: 1280px;
  @include breakpoint($small-down) {
    position: relative;
  }
  &_img_left {
    float: left;
    width: 100%;
    margin-top: 20px;
    @include breakpoint($medium-down) {
      width: 56%;
    }
    .egift_terms_cond {
      margin-top: 25px;
    }
  }
  &_form_right {
    float: right;
    width: 100%;
    margin: 20px 0 10px 0;
    p {
      padding: 0;
      margin: 0;
    }
    @include breakpoint($small-down) {
      float: none;
      width: 100%;
    }
  }
  .question_title {
    @include header--small;
    font-weight: bold;
  }
  .question {
    font-weight: bold;
    margin-bottom: 0;
  }
  .about_nav {
    ul {
      margin-bottom: 20px;
    }
  }
  .egift_button {
    border-bottom: 1px solid $color-black;
    margin-bottom: 20px;
    padding-bottom: 30px;
  }
  .egiftcard_about_head {
    border-bottom: 1px solid $color-black;
    margin-bottom: 30px;
  }
  .ec-loading {
    margin-top: 20px;
    padding: 6px 25px 5px;
    width: 33%;
    @include breakpoint($small-down) {
      width: 100%;
    }
  }
  &__form-section {
    float: left;
    margin-right: 3%;
    width: 52%;
    @include breakpoint($small-down) {
      float: none;
      width: 100%;
    }
    .gc_top_nav {
      margin-bottom: 20px;
      font-size: 16px;
      span a {
        text-decoration: none;
      }
      display: none;
      @include breakpoint($small-down) {
        display: block;
      }
    }
    .egift-header {
      text-transform: uppercase;
      @include breakpoint($small-down) {
        margin-bottom: 10px;
      }
      .egift_button {
        text-align: left;
        margin-top: 20px;
      }
    }
    .nav_hdr {
      padding: 10px;
    }
  }
  .gift_balance_val {
    margin: 20px 0 0 30px;
    font-weight: bold;
    font-size: 18px;
  }
  .error_messages {
    li {
      color: $color-red;
      margin-bottom: 10px;
    }
  }
  .egiftcard__accordian {
    float: left;
    margin-right: 40px;
    width: 22%;
    margin-right: 3%;
    @include breakpoint($small-down) {
      display: none;
    }
  }
  .egiftcard__item {
    margin-bottom: 25px;
    @include breakpoint($small-down) {
      margin-bottom: 0;
    }
    .form_element {
      width: 48%;
      @include breakpoint($small-down) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }
      margin-right: 2%;
      float: left;
      input,
      select {
        width: 100%;
        @include breakpoint($small-down) {
          border-width: 1px;
        }
      }
    }
    h2 {
      font-size: 16px;
      margin: 0 0 10px;
    }
  }
  .egiftcard__message-item {
    width: 100%;
    h2 {
      font-size: 16px;
      margin: 0 0 10px;
    }
    textarea {
      width: 98%;
      @include breakpoint($small-down) {
        border-width: 1px;
        width: 100%;
      }
    }
  }
  .egift_input_button {
    margin-top: 20px;
    width: 33%;
    @include breakpoint($small-down) {
      width: 100%;
    }
  }
  .form_element textarea {
    padding-top: 10px;
    height: 100px;
  }
}
