.network-tout {
  .basic-tout {
    &__content {
      padding: 90px 0px;
      // breadcrumb provided by basic-formatter
      .breadcrumb-active & {
        padding: 50px 0px 90px 0px;
      }
    }
    &__headline {
      padding: 0px 40px;
      @include breakpoint($medium-up) {
        padding: 0px;
      }
    }

    // dont make small for mobile
    &__headline.header--extra-large-alt {
      font-size: 53px;
      line-height: 1.22;
      letter-spacing: 0;
      font-weight: bold;
      margin-bottom: 15px;
    }

    // dont make small for mobile
    &__headline.header--large {
      font-size: 41px;
      line-height: 1.17;
      letter-spacing: -0.01em;
      font-weight: bold;
      margin-bottom: 15px;
    }
    &__description {
      p {
        @include breakpoint($medium-down) {
          font-family: $font--avenir-light;
          display: inline;
        }
      }
      margin: 0px 30px;
    }
  }
}

.network-collection-tout {
  @include breakpoint($medium-up) {
    padding-bottom: 25px;
  }
  .basic-tout {
    &__headline {
      @include breakpoint($medium-up) {
        margin: 50px 0px 30px 0px;
      }
    }
    // dont make small for mobile
    &__headline.header--large {
      font-size: 41px;
      line-height: 1.17;
      letter-spacing: -0.01em;
      font-weight: bold;
      margin-bottom: 15px;
    }
    &__description {
      margin: 0px;
      color: #020202;
      opacity: 0.6;
    }
  }
}

// merchandising downloads, busienss building downloads
.network-landing-carousel {
  .basic-image--responsive {
    height: calc(100vw - 100px);
    @include breakpoint($medium-up) {
      height: calc(33vw);
      max-height: 427px;
    }
  }
  .basic-image--responsive img {
    object-fit: contain;
    height: calc(100vw - 100px);
    @include breakpoint($medium-up) {
      object-fit: contain;
      height: calc(33vw);
      max-height: 427px;
    }
  }
  & > header > .basic-carousel__title {
    @include breakpoint($medium-up) {
      text-transform: uppercase;
    }
  }
  & > header > .basic-carousel__title .header--medium {
    font-size: 28px;
    line-height: 1.25;
    font-weight: bold;
    @include breakpoint($medium-up) {
      font-weight: normal;
    }
  }
  @include breakpoint($medium-up) {
    padding: 0px 15;
  }
}

.procontent-modal {
  background: $color-light-gray;
  color: #000;
  position: fixed;
  width: 100%;
  top: $header-height--mobile;
  z-index: 2;
  @include breakpoint($iphone6-up) {
    top: $header-height-large--mobile;
  }
  @include breakpoint($medium-up) {
    z-index: 9000;
    text-align: center;
    padding: 20px 30px;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    width: 600px;
  }
  .procontent-modal--close a {
    position: absolute;
    display: block;
    top: 20px;
    right: 20px;
    font-size: 20px;
    line-height: 20px;
    text-decoration: none;
  }
  .icon--close {
    font-size: 20px;
  }
  .signin-block__title {
    text-align: left;
    @include breakpoint($medium-up) {
      margin-top: 20px;
      text-align: center;
    }
  }
  .signin-block__subtitle {
    text-align: left;
    @include breakpoint($medium-up) {
      text-align: center;
    }
  }
  .signin-block__footer {
    margin-top: 30px;
    p {
      margin-bottom: 10px;
    }
  }
  .signin-block__forgot-password {
    clear: both;
  }
  input[type='submit'] {
    @include breakpoint($medium-up) {
      display: block;
      width: 100px;
      margin: 10px auto;
    }
  }
  .input-fields {
    @include breakpoint($medium-up) {
      padding: 0px 20px;
    }
  }
  input[name='EMAIL_ADDRESS'] {
    @include breakpoint($medium-up) {
      float: left;
      width: 49%;
    }
  }
  input[name='PASSWORD'] {
    @include breakpoint($medium-up) {
      float: right;
      width: 49%;
    }
  }
}

.pro-modal-overlay {
  display: none;
  background: $color-transparent--black;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
}

.pro-access-denied .pro-modal-overlay {
  display: block;
}

.network-content-grid {
  padding: 0px;
  .basic-grid__item {
    // allow carousel arrows to overlap
    overflow: visible;
    margin: 0px;
    border-top: 1px solid $color-light-gray;
    padding: 25px 0px;
    @include breakpoint($medium-up) {
      padding: 0px;
      border: none;
    }
  }
  &.basic-grid-formatter--padding {
    @include breakpoint($medium-up) {
      padding: 0 20px;
    }
  }
  .basic-grid__content {
    padding: 0px 10px;
  }
  .basic-image--responsive {
    @include breakpoint($medium-up) {
      height: calc(33vw);
      max-height: 427px;
    }
  }
  .basic-image--responsive img {
    @include breakpoint($medium-up) {
      object-fit: contain;
      height: calc(33vw);
      max-height: 427px;
    }
  }
}

.network-hero-carousel {
  // this button class shows up on desktop
  .hero-large__button {
    @include breakpoint($medium-up) {
      border: 1px solid #fff;
      padding: 6px 60px 5px 60px;
    }
  }
  // eh this only shows for mobile.
  .hero-large__cta-button {
    font-size: 19px;
  }
}

.footer__buy-in {
  margin-bottom: 50px;
  border-top: 1px solid $color-light-gray;
  margin-top: 30px;
  padding-top: 30px;
  @include breakpoint($medium-up) {
    border: none;
    margin: 0px;
    background: #f5f5f5;
    padding: 35px;
  }
  header h2 {
    text-align: center;
    margin-bottom: 0px;
    @include breakpoint($medium-up) {
      font-family: $font--avenir-heavy;
      font-size: 19px;
      margin-bottom: 30px;
    }
  }
  ul {
    @include breakpoint($medium-up) {
      display: table;
      margin: 0 auto;
    }
  }
  li {
    margin: 20px 10px;
    padding: 0px;
    @include breakpoint($medium-up) {
      display: table-cell;
      padding: 0px 10px;
    }
  }
  .button {
    font-size: 19px;
    width: 100%;
    text-transform: none;
    @include breakpoint($medium-up) {
      width: auto;
      font-size: 16px;
    }
  }
}

.basic-image {
  &__extole {
    cursor: pointer;
  }
}
