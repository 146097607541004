.social-login {
  &__container {
    clear: both;
    display: flex;
    flex-direction: column;
  }
  &__divider {
    order: 1;
  }
  &__terms {
    order: 2;
    margin-top: 0;
    .checkout--confirmation-page & {
      display: block;
      order: 3;
    }
  }
  &__email-opt-in {
    order: 3;
    padding-bottom: 15px;
    .checkout--confirmation-page & {
      order: 2;
    }
  }
  .fb-login-button {
    order: 4;
  }
}

.sign-in-page {
  .new-account {
    .privacy-policy {
      label::before {
        content: none;
      }
    }
  }
}
