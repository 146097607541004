@mixin loyalty-offers {
  .loyalty__panel__offers {
    @include loyalty-panel;
    clear: both;
    &__title {
      @include loyalty-panel-title;
      &--mobile {
        @include loyalty-panel-title-mobile;
      }
      &--pc {
        @include loyalty-panel-title-pc;
      }
    }
    &__subtitle {
      @include loyalty-panel-subtitle;
    }
    &__offer {
      background: $color-black;
      text-align: center;
      font-family: $font--avenir-book;
      font-size: 15px;
      position: relative;
      height: 200px;
      resize: vertical;
      color: $color-white;
      &.not_met {
        background: $color-thick-gray;
        color: $color-black;
        opacity: 0.7;
        .button--offers {
          cursor: none;
        }
      }
      &-wrapper {
        @include swap_direction(padding, 40px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        resize: vertical;
        width: 100%;
      }
      @include swap_direction(margin, 0 1px);
    }
    &__cta {
      @include swap_direction(margin, 15px auto 0);
      width: 150px;
      .loyalty__panel__offers {
        &__button {
          @include swap_direction(padding, 5px 6px);
          @include button--light;
          font-family: $font--avenir-medium;
          font-size: 14px;
          text-transform: uppercase;
          border: 1px solid $color-black;
          text-decoration: none;
        }
        &__selected {
          @include swap_direction(padding, 5px 6px);
          @include button--light;
          display: none;
          text-transform: uppercase;
          font-family: $font--avenir-medium;
          font-size: 14px;
        }
        &__remove {
          display: none;
          color: $color-white;
        }
      }
    }
    &__list {
      .slick-prev,
      .slick-next {
        top: 40%;
        &:before {
          color: $color-black;
        }
      }
      .slick-dots {
        li {
          color: $color-black;
          font-family: $font--avenir-book;
          font-size: 14px;
          &.slick-active {
            font-family: $font--avenir-heavy;
          }
        }
      }
    }
    &.is-selected {
      .loyalty__panel__offers__cta {
        .loyalty__panel__offers {
          &__remove {
            display: block !important;
            text-transform: uppercase;
            margin-top: 10px;
            font-size: 14px;
          }
          &__selected {
            display: block;
          }
          &__button {
            display: none;
          }
        }
      }
    }
    &__offer-expires,
    &__offer-terms {
      font-size: 0.9em;
    }
    &__offer-expires {
      font-style: italic;
    }
  }
  .loyalty__panel__offers__sku {
    @include swap_direction(padding, 15px);
    margin-bottom: 20px;
    &.is-selected {
      .loyalty__panel__offers__sku {
        &__remove {
          display: block !important;
          line-height: 33px;
          font-family: $font--avenir-medium;
          text-transform: uppercase;
        }
        &__product-wrapper {
          padding-bottom: 116px;
        }
      }
    }
    &__title-container {
      @include loyalty-panel-title;
      &--mobile {
        @include loyalty-panel-title-mobile;
      }
      &--pc {
        @include loyalty-panel-title-pc;
      }
    }
    &__sub-title {
      @include loyalty-panel-subtitle;
    }
    &__product {
      &-wrapper {
        text-align: center;
        position: relative;
        padding-bottom: 96px;
        &--points {
          opacity: 0.6;
        }
      }
      &__header {
        font-family: $font--avenir-heavy;
        font-size: 19px;
      }
      &__sub-header {
        font-family: $font--avenir-book;
        font-size: 16px;
        margin-top: 18px;
      }
    }
    &__disable__product {
      opacity: 0.5;
      .loyalty__panel__offers__sku {
        &__cta__container {
          display: none;
        }
      }
    }
    &__cta__container {
      @include swap_direction(padding, 1px 1px 30px 1px);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    &__cta {
      margin-top: 18px;
      .button {
        width: 100%;
        @include breakpoint($medium-up) {
          width: auto;
        }
      }
    }
    &__list {
      margin-bottom: 20px;
    }
    .slick-next:before,
    .slick-prev:before {
      color: $color-black;
    }
    .slick-dots {
      margin-top: 15px;
      @include breakpoint($medium-up) {
        margin-top: 30px;
      }
      li {
        @include swap_direction(margin, 0 5px);
        color: $color-black;
        font-family: $font--avenir-book;
        font-size: 14px;
        &.slick-active {
          font-family: $font--avenir-heavy;
        }
      }
    }
  }
  .checkout-panel--loyalty-offer-code {
    .checkout-panel__offer__heading {
      text-align: center;
      font-size: 19px;
    }
    .checkout__offer-code__wallet {
      &-wrapper {
        text-align: center;
      }
      &-item {
        &__header {
          font-size: 19px;
          font-family: $font--avenir-book;
        }
        &__description {
          @include swap_direction(padding, 0 40px);
          font-size: 14px;
          font-family: $font--avenir-book;
        }
        &__button-container {
          margin-top: 15px;
        }
      }
      .checkout__offer-code__wallet-item.is-applied {
        .checkout__offer-code__wallet {
          &-apply {
            display: none;
          }
          &-remove {
            display: block !important;
          }
          &-selected {
            display: inline-block;
          }
        }
      }
      &-apply,
      &-remove {
        text-transform: uppercase;
        font-family: $font--avenir-heavy;
        font-size: 14px;
      }
      &-apply {
        @include button--light;
        @include swap_direction(padding, 4px 18px);
        border: 1px solid $color-black;
        display: inline-block;
        text-decoration: none;
      }
      &-selected {
        @include extended-button;
        @include swap_direction(padding, 5px 6px);
        display: none;
      }
      &-remove {
        margin-top: 15px;
        display: none;
      }
    }
    .checkout__offer-code__rewards {
      &-link {
        font-family: $font--avenir-book;
        font-size: 16px;
      }
    }
    .slick-next,
    .slick-prev {
      &:before {
        color: $color-black;
      }
    }
    .slick-dots {
      @include swap_direction(margin, 15px 0 27px);
      li {
        width: 12px;
        height: 12px;
        button:before {
          width: 12px;
          height: 12px;
          color: $color-black;
          font-size: 11px;
          line-height: 15px;
        }
      }
      li.slick-active {
        button:before {
          color: $color-black;
        }
      }
    }
  }
  .checkout__sidebar {
    .loyalty_points_section {
      @include swap_direction(padding, 0 0.5em 1em 0.5em);
      text-align: center;
      span {
        font-family: $font--avenir-heavy;
      }
    }
  }
} // @mixin loyalty-offers
