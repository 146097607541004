// Live Chat Pages
.live-chat {
  padding: 10px;
  &__header {
    font-size: 24px;
    margin-bottom: 0;
  }
  &__sub-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  &__chat-hours {
    margin-bottom: 10px;
  }
}
/* Live Chat Container */
.lp_maximized {
  left: 20px;
}

.LPMimage {
  display: none;
}
/* To override style of Live chat container */
.LPMcontainer.LPMoverlay {
  a {
    text-decoration: underline;
    cursor: pointer;
    text-transform: capitalize;
  }
}

#lpChat input[type='radio'] {
  left: -9999px;
  position: absolute;
}
