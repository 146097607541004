///
/// @file state/_state.scss
/// also see base/helpers.scss
///
/// \brief Helper classes that allow us to control various states throughout css
///
/// These are placed last so that they can override any prior styling
///
///

@mixin pc-hidden {
  @include breakpoint($large-up) {
    display: none;
  }
}

@mixin mobile-hidden {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
  }
}

///
/// Hide only visually, but have it available for screenreaders: h5bp.com/v
///
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/// Extends the .visuallyhidden class to allow the element to be focusable
/// when navigated to via the keyboard: h5bp.com/p
///

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

///
/// Hide from both screenreaders and browsers: h5bp.com/u
///

.hidden,
.js_hidden {
  display: none !important;
  visibility: hidden;
}

///
/// Hide visually and from screenreaders, but maintain layout
///

.invisible {
  visibility: hidden;
}

///
/// hide/show classes to use instead of .hide() / .show()
/// DO NOT add !important declaration
///
.hide {
  display: none;
}

.show {
  display: block;
}

.shown {
  display: block !important;
}

.is-opaque {
  @include opaque;
  visibility: visible;
}

.is-transparent {
  @include transparent;
  visibility: hidden;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.flattened {
  height: 0;
  overflow: hidden;
}

.inflated {
  height: auto;
  overflow: visible;
}

.pc_hidden, // perlgem
.pc-hidden {
  @include pc-hidden;
}

.mobile_hidden, // perlgem
.mobile-hidden {
  @include mobile-hidden;
}

// some cases have both
// hide all
.mobile_hidden.pc_hidden,
.mobile-hidden.pc-hidden {
  display: none;
  @include breakpoint($medium-up) {
    display: none;
  }
}

///
/// Clearfix: contain floats
///
/// For modern browsers
/// 1. The space content is one way to avoid an Opera bug when the
///    `contenteditable` attribute is included anywhere else in the document.
///    Otherwise it causes space to appear at the top and bottom of elements
///    that receive the `clearfix` class.
/// 2. The use of `table` rather than `block` is only necessary if using
///    `:before` to contain the top-margins of child elements.
///

.clearfix:before,
.clearfix:after {
  content: ' '; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

///
/// Text Alignment
///
.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

///
/// Floats & Clearing floats
///
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.clear-both,
.clear {
  clear: both;
}

html.pro-user .consumer-only-element {
  display: none !important;
}

html.consumer-user .pro-content-element {
  display: none !important;
}
