//Local Color Variables
$color-light-blue: #5f93ec;
$color-lighter-blue: #5e91ec;
$color-light-red: #d74027;
$color-light-yellow: #fddd00;

.custom-outline {
  outline: 1px dotted $color-light-blue !important;
}

.site-header .button.custom-outline {
  outline: 2px dotted $color-light-yellow !important;
  opacity: 1;
  z-index: 0;
}

.selectBox-selected,
.cart-item__info__header a:focus {
  border: 1px dotted $color-lighter-blue !important;
}

a.checkedEmpty span {
  color: $color-light-red !important;
}

.payment_method-container .checkbox.checkbox--paypal {
  clear: both;
}

.site-footer__wrap ul li a.footer__quicklink__link {
  display: inline-block;
}

.adpl input:required {
  + label:before {
    content: '*' attr(placeholder);
  }
  &.js-label-mode + label:before {
    content: '*' attr(alt);
  }
}

input[type='submit'].gnav__locator__search__submit.custom-outline {
  opacity: 1;
  z-index: 0;
  outline: 2px dotted $color-light-yellow !important;
}

.adpl .checkout-registration .password input:required + label:before {
  content: '' attr(placeholder) !important;
}

.link_item--email.custom-outline {
  border: 1px dotted $color-light-blue;
  outline: none !important;
}

a {
  &.selectBox.expiration_month.selectBox-required.custom-outline,
  &.change-address.billing_address_radio.custom-outline,
  &.billing_add_new_addres.billing_address_radio.custom-outline,
  &.edit.js-payment-panel-edit.custom-outline {
    border: 1px dotted $color-light-blue !important;
    outline: none !important;
  }
}

.full-focus-element.custom-outline,
.address-options__links > a.custom-outline {
  border: 1px dotted $color-light-blue !important;
  outline: none !important;
}

.column.thumb.cart-items__item--thumb > a,
.cart--item__remove-form a {
  margin: 1px;
}

body.checkout-optimize .address .billing_address_radio {
  margin: 1em 1px;
}

.site-header__contact {
  line-height: 20px;
  padding: 2px 2px 2px 2px;
  margin-top: 4px;
}
