@charset "UTF-8";

// Slick Variables
$slick-loader-path: $img-base-url !default;
$slick-arrow-color: $color-gray !default;
$slick-dot-color: $color-gray !default;
$slick-dot-color-active: $color-gray !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 0.8 !default;
$slick-opacity-not-active: 0.25 !default;
/* Slider */

.slick-list {
  .slick-loading & {
    //background: slick-image-url("ajax-loader.gif") center center no-repeat;
  }
}
/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  width: 60px;
  height: 70px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 10;
  &:hover {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      .no-touchevents & {
        opacity: $slick-opacity-on-hover;
      }
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  &:before {
    font-size: 40px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //text-shadow: 0 1px 1px $color-black;
  }
}

.slick-prev {
  @include icon('arrow_carousel_left');
  left: 0;
  [dir='rtl'] & {
    @include icon('arrow_carousel_right');
    left: auto;
    right: 0;
  }
}

.slick-next {
  @include icon('arrow_carousel_right');
  right: 0;
  [dir='rtl'] & {
    @include icon('arrow_carousel_left');
    left: 0;
    right: auto;
  }
}
/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 10px 0;
  width: 100%;
  color: $color-gray;
  @include breakpoint($large-up) {
    margin-top: 15px;
  }
  .carousel-dots--overlay & {
    @include breakpoint($large-up) {
      position: absolute;
      margin: 0;
      bottom: 12px;
      z-index: 100;
    }
  }
  li {
    position: relative;
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 0 3px;
    padding: 0;
    cursor: pointer;
    button {
      @include icon('utility_pager_empty');
      border: 0;
      background: transparent;
      display: block;
      height: 10px;
      width: 10px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 0;
      cursor: pointer;
      &:hover {
        outline: none;
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 10px;
        font-size: 10px;
        line-height: 10px;
        text-align: center;
        color: $slick-dot-color;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    @include breakpoint($large-up) {
      height: 15px;
      width: 15px;
      margin: 0 5px;
      button {
        height: 15px;
        width: 15px;
        &:before {
          width: 15px;
          height: 15px;
          font-size: 15px;
          line-height: 15px;
        }
      }
    }
    &.slick-active button {
      @include icon('utility_pager_filled');
      &:before {
        color: $slick-dot-color-active;
      }
    }
  }
}
