@mixin loyalty-points {
  .loyalty__panel__points {
    @include loyalty-panel;
    @include swap_direction(padding, 15px);
    position: relative;
    @include breakpoint($medium-up) {
      float: none;
      width: 100%;
      text-align: center;
      &.loyalty__has-max-tier,
      &.loyalty__has-pending-points {
        float: none;
        width: auto;
      }
    }
    progress {
      height: 13px;
      width: 32%;
      border: 1px solid $color-black;
      border-radius: 0;
      background-color: $color-thick-gray;
      color: $color-black;
      @include breakpoint($medium-up) {
        margin-right: 3px;
      }
      &::-webkit-progress-bar {
        background-color: $color-thick-gray;
        border-radius: 0;
      }
      &::-webkit-progress-value {
        background-color: $color-black;
        border-right: 1px solid $color-black;
      }
      &::-moz-progress-bar {
        background-color: $color-black;
        border-right: 1px solid $color-black;
      }
      &.level__progress[value='0'],
      &.level__progress--one[value='249'],
      &.level__progress--two[value='499'],
      &.level__progress--full {
        &::-webkit-progress-value {
          border-right: 0;
        }
        &::-moz-progress-bar {
          border-right: 0;
        }
      }
      &.level__progress--two[value='499'] {
        &::-webkit-progress-value {
          border-right: 0;
        }
        &::-moz-progress-bar {
          border-right: 0;
        }
      }
    }
    &__title {
      @include loyalty-panel-title;
      &--mobile {
        @include loyalty-panel-title-mobile;
      }
      &--pc {
        @include loyalty-panel-title-pc;
      }
    }
    &__status {
      margin-bottom: 20px;
    }
    &__progress__wrapper {
      text-align: center;
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0 auto);
        max-width: 550px;
      }
    }
    &__status__available,
    &__tier__available {
      @include swap_direction(padding, 15px 10px);
      float: left;
      width: 45%;
    }
    &__status__available {
      text-align: center;
      @include breakpoint($medium-up) {
        text-align: #{$rdirection};
        width: 50%;
        padding-right: 25px;
      }
      &--amount {
        font-family: $font--loyalty-bumber;
        font-size: 43px;
        .icon {
          padding-left: 5px;
        }
      }
    }
    &__anniversary {
      @include swap_direction(margin, 0);
      font-family: $font--avenir-heavy;
      font-size: 16px;
    }
    &__query {
      &-link {
        font-family: $font--avenir-light;
        font-size: 16px;
        color: $color-gray;
        @include breakpoint($medium-up) {
          font-size: 14px;
        }
      }
    }
    &__disclaimer {
      font-family: $font--avenir-book;
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0 auto 20px);
        width: 70%;
      }
    }
    &__tier__available {
      border-left: 2px solid $color-light-gray;
      width: 55%;
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 10px 15px);
        width: 50%;
        text-align: #{$ldirection};
      }
      &--level,
      &--name {
        @include swap_direction(margin, 0);
      }
      &--level {
        font-family: $font--avenir-book;
        font-size: 16px;
        @include breakpoint($medium-up) {
          font-size: 19px;
          line-height: 30px;
        }
      }
      &--name {
        font-family: $font--avenir-heavy;
        font-size: 19px;
        text-transform: uppercase;
        @include breakpoint($medium-up) {
          font-size: 32px;
          line-height: 32px;
        }
      }
    }
    &--marketing {
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0 auto);
        max-width: 928px;
      }
    }
  }
  .loyalty__panel__banner {
    height: 175px;
    text-align: center;
    background-image: url(/media/images/global/page-header-background-mobile.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: 20px;
    @include breakpoint($medium-up) {
      background-image: url(/media/images/global/marketing-page-header-background-desktop.jpg);
      padding-top: 28px;
      height: 250px;
    }
    &__image {
      width: 267px;
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0 auto);
        width: 492px;
      }
      &--mobile {
        @include breakpoint($medium-up) {
          display: none;
        }
      }
    }
    &__text {
      @include swap_direction(padding, 9px 60px 0);
      font-family: $font--avenir-book;
      line-height: 19px;
      font-size: 19px;
      color: $color-white;
      @include breakpoint($medium-up) {
        font-size: 25px;
      }
    }
  }
  .loyalty__panel__benefits {
    .submitted {
      display: none;
    }
    h2 {
      display: none;
    }
  }
  .confirmation-content {
    &__loyalty__user {
      padding-top: 10px;
      margin-bottom: 30px;
      font-family: $font--avenir-book;
      a {
        font-family: $font--avenir-heavy;
      }
    }
  }
} // @mixin loyalty-points
