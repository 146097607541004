/**
 * Pagination
 */
.pagination-container {
  //border-top: solid 1px $color-black;
  .pagination {
    text-align: center;
    padding: 1em 0;
    margin: 0 auto;
    text-transform: uppercase;
    .paging-select {
      margin-bottom: 1em;
    }
    span {
      display: inline-block;
      &:after {
        //content: "\00b7";
      }
      &.last:after {
        //content: "";
      }
      a,
      strong {
        display: inline-block;
        padding: 0 1em;
      }
      &.first a {
        padding-left: 0;
      }
      &.last a {
        padding-right: 0;
      }
      // hide paging links
      &.page {
        //display: none;
      }
    }

    // mobile does not have span wrappers
    @include breakpoint($small-down) {
      a {
        display: inline-block;
        padding: 0 1em;
        &.first a {
          padding-left: 0;
        }
        &.last a {
          padding-right: 0;
        }
      }
    }
  }
}
