.site-footer__section__email {
  .site-footer__wrap {
    height: 220px;
    background-image: url(/media/images/global/email-signup-dropdown-footer-background-mobile.jpg);
    @include breakpoint($medium-up) {
      background-image: url(/media/images/global/email-signup-dropdown-footer-background-desktop.jpg);
      height: 268px;
      background-attachment: initial;
    }
  }
}

.site-email-signup {
  .site-header__section--subnav & {
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 48px 10px 10px 10px);
      background-image: url(/media/images/global/email-signup-dropdown-footer-background-desktop.jpg);
    }
  }
  &__title {
    margin-bottom: 12px;
    text-transform: uppercase;
    font-size: 21px;
    line-height: 21px;
    .sticky__menu__formatter & {
      margin-bottom: 7px;
    }
    @include breakpoint($medium-up) {
      font-size: 30px;
      line-height: 31px;
      .site-header__section--subnav & {
        margin-bottom: 7px;
      }
    }
  }
  &__copy {
    font-size: 18px;
    margin-bottom: 18px;
    font-family: $font--avenir-medium;
    line-height: 1em;
    .sticky__menu__formatter & {
      margin-bottom: 12px;
    }
    @include breakpoint($medium-up) {
      font-family: $font--avenir-heavy;
      margin-bottom: 30px;
      font-size: 22px;
    }
  }
  &__fields {
    .site-email-signup__field {
      text-align: left;
      @include breakpoint($medium-up) {
        text-align: center;
        font-size: 21px;
      }
    }
  }
  &__loyalty-message {
    @include swap_direction(padding, 9px 25px 0 30px);
    @include breakpoint($medium-up) {
      @include swap_direction(padding, 35px 30px 0 30px);
    }
    text-align: center;
    span {
      float: #{$ldirection};
      font-size: 22px;
      margin-top: 9px;
      @include breakpoint($medium-up) {
        float: none;
        margin-top: 0;
      }
    }
    p {
      float: #{$ldirection};
      width: 86%;
      padding-left: 10px;
      text-align: #{$ldirection};
      font-family: $font--avenir-book;
      margin-bottom: 0;
      @include breakpoint($medium-up) {
        float: none;
        display: inline;
      }
    }
    a {
      text-decoration: underline;
      font-family: $font--avenir-heavy;
      color: $color-black;
    }
  }
}

.site-email-signup__success {
  @include swap_direction(padding, 1.5em 0);
  &-header {
    @include h3;
  }
  &-offer {
    margin-top: 15px;
    font-size: 19px;
    font-family: $font--avenir-book;
    @include breakpoint($medium-up) {
      font-size: 16px;
    }
  }
  &-terms {
    @include swap_direction(margin, 18px 0);
    .link {
      color: $color-gray;
      border-bottom: 1px solid $color-gray;
      font-family: $font--avenir-book;
    }
  }
  &-cta {
    .button--medium {
      @include swap_direction(padding, 13px 18px);
    }
  }
  .site-display-signup__success {
    &-loyalty-message {
      font-family: $font--avenir-medium;
      .icon {
        font-size: 22px;
      }
    }
  }
}

.sticky__menu__formatter {
  .sticky__menu__subnav {
    height: 220px;
    @include breakpoint($medium-up) {
      height: 268px;
    }
  }
}
