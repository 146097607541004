@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    -webkit-appearance: -apple-pay-button;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
  .apple-pay-button-white {
    -apple-pay-button-style: white;
  }
  .apple-pay-button-black {
    -apple-pay-button-style: black;
  }
  .apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 5px;
    padding: 0;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
  }
  .apple-pay-button-black {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: $color-white;
  }
  .apple-pay-button-white {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: $color-white;
  }
  .apple-pay-button-white-with-line {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: $color-white;
    border: 5px solid $color-black;
  }
}

body.checkout-optimize {
  .viewcart {
    .sticky-checkout-button {
      position: fixed;
      bottom: 0;
      #{$ldirection}: 0;
      width: 100%;
      margin: 0;
      z-index: 99999;
      font-weight: normal;
      font-size: 16px;
      padding: 0;
      border: 10px solid $color-white;
      visibility: visible;
      height: auto;
      background: $color-white;
      .button {
        padding: 0;
        border: none;
        height: 55px;
        line-height: 4;
        border-radius: 4px;
      }
      .apple-pay-button-container {
        margin-bottom: 4px;
        padding: 0 2px;
        a {
          height: 55px;
          width: 100%;
        }
        .checkout-with {
          position: relative;
          #{$ldirection}: 10px;
          margin-#{$ldirection}: 5px;
        }
      }
      .max-price-freeze {
        pointer-events: none;
      }
      .checkout-panel--order-total {
        background: none;
        line-height: 1.6;
        margin-bottom: 10px;
        .checkout-buttons-content__afterpay {
          color: $color-dark-gray;
          font-size: 15px;
          .order-summary-content__afterpay {
            &-logo {
              vertical-align: middle;
            }
            &-learnmore {
              display: none;
            }
          }
        }
      }
      .payment-types {
        margin: 10px 25px;
        &__icon {
          width: 27px;
          margin: 0 6px;
          vertical-align: middle;
          &.afterpay {
            width: 70px;
          }
        }
      }
    }
    .checkout-panel--checkout-button {
      .apple-pay-button-container {
        height: 55px;
        margin-bottom: 4px;
        padding: 0 2px;
        width: 100%;
        cursor: pointer;
      }
    }
    .applepay-overlay-bg {
      position: fixed;
      top: 0;
      bottom: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      background-color: $color-black;
      opacity: 0.5;
      z-index: 1000;
      display: none;
    }
    .account-login-button {
      border-top: 1px solid $color-bg-gray;
      border-bottom: 1px solid $color-bg-gray;
      margin: 15px 0 0;
    }
    .checkout-login {
      &__signin {
        margin: 0;
        padding: 0.5em 0.35em;
        display: block;
        position: relative;
        text-decoration: none;
        .arrow {
          position: absolute;
          top: 14px;
          #{$rdirection}: 0;
        }
      }
      &__content {
        padding: 13px 8px;
        background-color: $color-bg-gray;
      }
      &__email {
        font-size: 16px;
      }
      &__signout {
        text-transform: uppercase;
        border-bottom: 1px solid $color-bg-gray;
        font-size: 14px;
        float: #{$rdirection};
        margin-top: 2px;
      }
    }
    .viewcart-shortcuts-panel {
      @include swap_direction(padding, 15px 8px 16px 7px);
      border-bottom: 1px solid $color-bg-gray;
      .shortcuts-content__list {
        &--item {
          float: #{$ldirection};
          width: 50%;
          &:last-child {
            text-align: #{$rdirection};
            float: #{$rdirection};
          }
        }
        &--link {
          text-transform: uppercase;
          border-bottom: 1px solid $color-bg-gray;
          font-size: 14px;
        }
      }
    }
    .signin-overlay {
      position: fixed;
      display: block;
      z-index: 1003;
      width: 100%;
      height: 100%;
      background: $color-white;
      overflow: scroll;
      top: 0;
      #{$ldirection}: -150px;
      margin: 0;
      .checkout-header,
      .sign-in-panel {
        display: block;
      }
      .checkout-panel {
        margin: 0 10px;
        max-width: 1280px;
        &__heading {
          display: none;
        }
        .error-messages {
          font-size: 14px;
          text-align: center;
          color: $color-red;
          padding: 10px;
          margin-top: 5px;
        }
      }
      .line-divider {
        border: none;
        margin: 0;
      }
    }
    .site-header-signin {
      height: 50px;
      width: 100%;
      top: 0;
      background: $color-black;
      color: $color-white;
      .mobile-back {
        font-size: 22px;
        #{$ldirection}: 10px;
      }
    }
    .viewcart-samples-overlay {
      position: fixed;
      z-index: 1003;
      width: 100%;
      height: 100%;
      background: $color-white;
      overflow: scroll;
      top: 0;
      #{$ldirection}: -100px;
      .samples-page {
        .product-img {
          height: 155px;
        }
        .product-brief__title {
          height: 51px;
        }
        .product-size {
          height: 17px;
        }
      }
      .checkout__container {
        padding-bottom: 50px;
      }
    }
    .new-user-link,
    .return-user-link {
      a {
        display: block;
        text-transform: uppercase;
        text-align: center;
      }
    }
    .new-user-link {
      padding-bottom: 0;
    }
    .social-login {
      &__container {
        padding-top: 0;
      }
    }
  }
  .apple_pay {
    .remove-sample {
      display: none;
    }
  }
}

.checkout-panel--bottom-viewcart-buttons {
  .apple-pay-button-container {
    height: 55px;
    margin-bottom: 10px;
    cursor: pointer;
  }
}
.focus_pointer {
  height: 100%;
  overflow: auto;
  body {
    width: 100%;
    overflow: auto;
    .page-wrapper {
      position: relative;
      .applepay-signin-overlay {
        height: 100%;
        overflow: scroll;
      }
    }
  }
}

.account-login-button,
.viewcart-shortcuts-panel {
  @include breakpoint($medium-range) {
    display: none !important;
  }
}
