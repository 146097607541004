@mixin loyalty-transactions {
  .loyalty__panel__transactions {
    @include loyalty-panel;
    @include swap_direction(padding, 20px);
    .loyalty__panel__transactions__title {
      @include loyalty-panel-title;
    }
    h2 {
      @include loyalty-panel-title;
      @include breakpoint($medium-up) {
        text-align: #{$ldirection};
        margin-bottom: 15px;
      }
    }
    &__list__column {
      &-date {
        padding-left: 0;
        @include breakpoint($medium-up) {
          padding-left: 20px;
        }
      }
      &-activity {
        text-align: #{$rdirection};
        padding-right: 0;
        @include breakpoint($medium-up) {
          text-align: #{$ldirection};
        }
      }
    }
    &__paginate {
      @include swap_direction(padding, 30px);
      text-align: center;
      font-size: 16px;
      font-family: $font--avenir-book;
    }
    &__rewards__links {
      text-align: center;
      .button {
        font-size: 19px;
        width: 100%;
        text-transform: initial;
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 6px 14px);
          width: auto;
        }
      }
    }
    th,
    td {
      padding: 10px;
      font-size: 16px;
      font-family: $font--avenir-book;
    }
    th {
      @include swap_direction(padding, 10px 0 20px);
      text-transform: uppercase;
      font-size: 19px;
      font-family: $font--avenir-heavy;
      border-bottom: 1px solid $color-thick-gray;
      height: 60px;
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 10px 20px 20px);
        .loyalty__panel__transactions__list__column-date {
          padding-left: 20px;
        }
      }
    }
    table {
      width: 100%;
      border-bottom: 1px solid $color-black;
    }
    &__list {
      &__column-points,
      &__column-earned,
      &__column-spent,
      &__column-total {
        display: none;
      }
    }
    &__transaction--level {
      height: 60px;
      text-align: center;
      background: $color-thick-gray;
      td {
        @include swap_direction(padding, 25px);
        font-family: $font--avenir-medium;
        font-size: 14px;
        text-align: center;
        text-transform: uppercase;
        @include breakpoint($medium-up) {
          font-size: 16px;
        }
      }
    }
    &__total {
      height: 60px;
      text-transform: uppercase;
      font-size: 19px;
      border-top: 1px solid $color-thick-gray;
      td {
        @include swap_direction(padding, 22px 0);
        font-family: $font--avenir-heavy;
        font-size: 19px;
      }
      &-amount {
        text-align: #{$rdirection};
      }
    }
    @include breakpoint($medium-up) {
      .mobile {
        display: none;
      }
      &__list {
        &__column-points,
        &__column-earned,
        &__column-spent,
        &__column-total {
          display: table-cell;
          text-align: #{$rdirection};
          width: 150px;
          padding-right: 20px;
        }
        .mobile-transaction-history & {
          display: none;
        }
      }
    }
  }
} // @mixin loyalty-transactions
