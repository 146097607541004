///
/// @file template_api/endeca/_search_results.scss
///
/// \brief Search Results
///
/// @todo test in header and redo styles
/// @setup new brand - these styles can be customized per design
///
.search-wrapper {
  @include clearfix;
  &__header {
    text-align: center;
  }
  &__loading {
    text-align: center;
    padding-bottom: 20px;
  }
  .summary {
    text-align: center;
    .no-term__text,
    .search-no-results {
      text-transform: none;
      text-align: center;
    }
    @include breakpoint($medium-up) {
      text-align: left;
      padding: 20px 0;
      text-transform: uppercase;
      span {
        font-weight: bold;
        .not-bold {
          font-weight: normal;
        }
      }
      p {
        margin: 0;
      }
    }
  }
  .results {
    &__products {
      .products {
        &__header {
          // temp hide sort
          display: none;
        }
      }
    }
    &__content {
      .results-summary {
        // temp hide sort
        display: none;
      }
    }
  }
  .breadcrumb {
    padding: 0.5em;
  }
  .breadcrumb__remove--close {
    display: inline-block;
  }
}

// no results
.search-wrapper.no-results {
  .results__products--column {
    display: none;
  }
  .search-wrapper__results {
    float: none;
    width: 100%;
    @include swap_direction(padding, 0 20px 0 20px);
  }
}

// results column
.search-wrapper__results {
  float: left;
  width: 25%;
  @include swap_direction(padding, 0 40px 0 20px);
}

.results__header--column,
.results__header--recent-searches {
  @include breakpoint($medium-up) {
    border-top: $color-black solid 1px;
    border-bottom: $color-black solid 1px;
    margin-bottom: 1em;
    a {
      text-decoration: none;
    }
    border: none;
    position: relative;
    display: block;
    padding-bottom: 1em;
  }
  h3.results-header {
    @include h5;
    text-transform: uppercase;
  }
}

// products column
.results__products--column {
  margin: 0;
  border-bottom: $color-black solid 1px;
  @include breakpoint($medium-up) {
    float: left;
    width: 75%;
    border: none;
    padding-bottom: 60px;
  }
  .results__header--dimensions {
    text-align: center;
    margin-bottom: 2em;
  }
  .product-grid {
    @include clearfix;
  }
}

// dimensions
.results__header--dimensions {
  .results-header {
    cursor: pointer;
  }
}

.products__content {
  @include clearfix;
}

.search-wrapper__header {
  margin-bottom: 15px;
}

// mobile
.search-results__refinements {
  position: relative;
  text-align: center;
  padding: 0 10px;
  .search-results__accordion {
    position: absolute;
    top: 36px; // results-header + border
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: $color-white;
    padding: 16px 12px;
    border-bottom: 1px solid $color-light-gray;
  }
  div.results-header {
    @include h4;
    line-height: 34px;
    border-bottom: 2px solid $color-black;
    margin-bottom: 7px;
    .icon {
      font-size: 8px;
      color: $color-gray;
    }
  }
  dt.results-header {
    @include h5;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  .accordion-content {
    margin-bottom: 20px;
    dd {
      display: inline-block;
      margin: 8px 6px;
    }
  }
}

.search-results__products {
}

// old

// temporary 2 col layout for genie only
// @setup new brand may remove these classes from markup
// or adjust accordingly
// genie_base/template_api/endeca/esearch_results_pc_v1.mustache

.endeca-product-result {
}
// .esearch-product__image {
//   @include breakpoint($medium-up) {
//     float: left;
//     width: 100px;
//   }
// }
// .esearch-product__content {
//   @include breakpoint($medium-up) {
//     margin-left: 120px;
//   }
// }

// plain styles for responsive result on search page
.endeca-product-result {
  padding: 1em 0;
}

.endeca-test-image {
  width: 100px;
  height: 125px;
  background-color: $color-gray;
  &--small {
    width: 50px;
    height: 62px;
  }
}

.no-results {
  .recent-searches {
    display: none;
  }
}
