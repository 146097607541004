.site-email-signup {
  display: none;
  color: $color-black;
  text-align: center;
  @include breakpoint($medium-up) {
    position: relative;
    @include min-width($min-width);
    max-width: $max-width-large;
    margin: 0 auto;
  }
  &__checkbox,
  &__terms-conditions__more {
    a {
      color: inherit;
      text-decoration: underline;
    }
  }
  &__checkbox {
    &__text {
      p {
        display: inherit;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  &__wrapper {
    height: 100%;
    position: relative;
  }
  &__content {
    @include transform(translate(-50%, -50%));
    top: 30%;
    #{$ldirection}: 50%;
    position: absolute;
    width: 100%;
    .site-email-signup__title {
      margin-bottom: 10px;
    }
  }
}

.site-email-signup__close {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  line-height: 20px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.site-email-signup__title {
  @include font-smoothing;
  font-family: $font--avenir-heavy;
  font-size: 28px;
  line-height: 1.17;
  letter-spacing: -0.01em;
  margin-bottom: 20px;
  @include breakpoint($medium-up) {
    font-size: 41px;
    margin-bottom: 28px;
  }
}

.site-email-signup__fields {
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  .icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    color: $color-white;
    line-height: 50px;
    font-size: 30px;
    background: $color-black;
  }
}

input[type='email'].site-email-signup__field {
  height: 50px;
  line-height: 48px;
  padding-#{$rdirection}: 52px;
  width: 100%;
  text-align: center;
}

input[type='tel'].site-email-signup__field {
  display: none;
}

input[type='submit'].site-email-signup__submit {
  @include squish-text;
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  opacity: 0;
  z-index: 2;
}

.site-email-signup__terms-conditions {
  clear: both;
  padding: 10px 0 0 0;
  display: none;
  text-align: #{$ldirection};
}

// header
.site-header__section--subnav {
  .site-email-signup__title {
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .site-email-signup__subtitle {
    margin-bottom: 16px;
    font-family: $font--avenir-medium;
    font-size: 25px;
    letter-spacing: -0.025em;
  }
  .subnav__email__signup {
    background-color: $color-lighter-gray;
  }
  .site-email-signup__close {
    display: block;
  }
  .site-email-signup {
    padding: 88px 10px 10px 10px;
    height: 267px;
    background: url(/media/export/cms/gnav/gnav_email_signup.jpg) no-repeat center center;
    background-size: auto;
  }
  .site-email-signup__content {
    max-width: 660px;
    width: 100%;
  }
  .bold {
    font-weight: bold;
  }
}

.site-email-signup__success {
  position: relative;
  text-align: center;
  padding: 2em 0;
  &-header {
    text-transform: uppercase;
  }
  &-offer {
    line-height: 1.5em;
    &__code {
      text-transform: uppercase;
    }
  }
  &-terms {
    margin-top: 1em;
    margin-bottom: 2em;
  }
  &-cta {
    margin-bottom: 2em;
    .button--medium {
      min-width: 172px;
    }
  }
}

// footer
.site-footer__section__email {
  background-color: $color-lighter-gray;
  html.pro-user &,
  body.pro-access-denied & {
    display: none;
  }
  .site-footer__wrap {
    background-image: url(/media/images/global/bb_script_bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 177px;
    padding: 32px 10px 16px 10px;
    @include breakpoint($medium-up) {
      height: 210px;
      padding: 35px 40px;
      max-width: 1280px;
      background-image: url('/media/images/global/bb_script_bg-parallax.jpg');
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
    }
  }
  .site-email-signup {
    &__content {
      margin-top: 60px;
    }
    &__terms-conditions {
      max-width: none;
    }
  }
}

.footer__email__signup {
  .site-email-signup {
    display: block;
  }
}

// sticky nav - mobile
.sticky__menu__formatter {
  .sticky__menu__subnav {
    background-color: $color-lighter-gray;
  }
  .sticky__menu__subnav__content {
    position: relative;
    //background: url(/media/images/global/bb_script_bg.jpg) no-repeat center center;
    background: url(/media/export/cms/gnav/gnav_email_signup_sm.jpg) no-repeat center center;
    background-size: cover;
    height: 191px; //177px;
    //padding: 32px 10px 16px 10px;
    padding: 20px 10px 16px 10px;
    @include breakpoint($medium-up) {
      height: 210px;
      padding: 35px 40px;
    }
  }
  .sticky__menu__subnav--close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
  }
  .site-email-signup__title {
    margin-bottom: 0;
  }
  .site-email-signup__subtitle {
    font-family: $font--avenir-medium;
    font-size: 25px;
    letter-spacing: -0.025em;
    width: 40%;
    margin: 0 auto 16px auto;
  }
  .site-email-signup {
    display: block;
  }
  input[type='email'].site-email-signup__field {
    text-align: #{$ldirection};
  }
}
//Legal Text Updates
$legal_update_text: false !default;

@if $legal_update_text {
  .site-header__section--subnav {
    .site-email-signup {
      height: 315px;
      background-size: 95% 100%;
      &__copy {
        font-size: 16px;
        width: 66%;
        margin: 0 auto;
        padding-bottom: 20px;
        line-height: 1.5;
      }
    }
  }
  .gnav-util--account {
    .gnav-util__content {
      @include breakpoint($medium-up) {
        width: 400px;
      }
      .signin-legal__updates {
        font-size: 14px;
        margin-top: 5px;
        line-height: normal;
        p {
          margin: 0;
        }
      }
    }
    .social-login.gnav {
      padding: 0;
      .social-login__container {
        display: flex;
        flex-direction: column;
        .fb-overlay-container {
          order: 3;
        }
        .social-login__email-opt-in {
          order: 2;
          line-height: normal;
          font-size: 14px;
          padding: 10px 0;
          p {
            margin-bottom: 10px;
          }
          label::before {
            line-height: normal;
          }
        }
        .social-login__terms {
          order: 1;
          margin-top: 0;
          font-size: 14px;
          line-height: normal;
        }
        .social-login__divider {
          margin-bottom: 10px;
          margin-top: 10px;
        }
      }
    }
  }
  .sticky__menu__formatter {
    &.sticky__menu__subnav__active {
      .sticky__menu__subnav {
        height: 285px;
        &__content {
          height: 285px;
        }
      }
      .site-email-signup__copy {
        p {
          padding: 10px 10px 0 10px;
          font-size: 14px;
          line-height: 20px;
          .legal-links {
            color: $color-black;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .site-footer__section__email {
    .site-footer__wrap {
      height: 275px;
      background-position: 95% 100%;
      padding: 25px 40px;
      .site-email-signup {
        &__copy {
          margin-bottom: 10px;
          p {
            font-size: 13px;
            margin: 10px auto 0;
            width: 70%;
            .legal-links {
              text-decoration: underline;
            }
          }
        }
        &__loyalty-message {
          padding-top: 25px;
        }
      }
    }
  }
}
