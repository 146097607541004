.cs-page {
  max-width: $max-width;
  margin: 0 auto;
  padding-bottom: 30px;
  &__navigation {
    h3 {
      font-size: 16px;
      text-transform: uppercase;
    }
    .cs-menu__children,
    .cs-nav-menu__children {
      padding: 0;
      line-height: 2em;
      li {
        a {
          text-decoration: none;
          text-transform: uppercase;
        }
      }
    }
    .collapsible-nav-menu__static,
    .collapsible-menu__static {
      a {
        text-decoration: none;
        text-transform: uppercase;
      }
    }
    .collapsible-sub-nav-menu {
      margin-bottom: 20px;
    }
    .collapsible-nav-menu {
      @include breakpoint($landscape-up) {
        padding-top: 85px;
      }
    }
  }
  &__title {
    text-transform: uppercase;
  }
  &__content-item {
    .collapsible-block {
      padding: 0;
      &__title {
        font-family: $font--avenir-heavy;
        padding: 10px 0;
        span {
          width: 90%;
          display: inline-block;
        }
      }
      &__content {
        padding-top: 0;
      }
      &__icon {
        top: 10px;
      }
    }
    #contact_us {
      h2 {
        margin: 30px 0 5px 0;
        font-size: 16px;
      }
      ul {
        margin: 0;
        li {
          margin-left: 20px;
        }
        li:last-child {
          margin-bottom: 20px;
        }
      }
    }
    .content-header {
      border-bottom: 2px solid $color-black;
      margin-bottom: 30px;
      .breadcrumbs {
        text-transform: uppercase;
        font-size: 2em;
        margin-bottom: 20px;
      }
      .support {
        float: right;
        margin-top: 10px;
        .chat_container {
          visibility: visible;
        }
        ul {
          margin-top: 5px;
          @include breakpoint($small-down) {
            margin: 0;
          }
        }
        li {
          float: left;
          list-style-type: none;
          line-height: 1;
          margin-left: 20px;
          @include breakpoint($small-down) {
            margin: 0 8px 10px 0;
          }
        }
      }
    }
    .cs-contact-form__section {
      line-height: 1.375;
      max-width: 650px;
    }
    #contact_us {
      .fieldset {
        float: left;
        width: 45%;
        margin-bottom: 20px;
        @include breakpoint($small-down) {
          float: none;
          width: 100%;
        }
        #form--contact_us--field--QUESTION {
          width: 95%;
          @include breakpoint($small-down) {
            width: 100%;
          }
        }
        &.my_order {
          p {
            margin: 0;
          }
        }
        &.my_message {
          h2 {
            margin-top: 0;
          }
          .label {
            display: none;
          }
        }
        .select-box__label {
          width: 100%;
          height: 48px;
          font-size: 16px;
          line-height: 48px;
        }
        .select-box__label:after,
        .select-box__label.open:after {
          width: 20px;
          top: 12px;
        }
        input[type='text'],
        input[type='email'],
        input[type='tel'],
        input[type='search'],
        input[type='password'],
        select,
        .select-box {
          width: 90%;
          @include breakpoint($small-down) {
            width: 100%;
          }
          .select-box__options.open {
            top: 47px;
          }
        }
        .field-label {
          display: none;
        }
        input.adpl--processed + label {
          margin-bottom: 0em;
        }
      }
      .cs-contact-form__errors {
        li {
          color: $color-red;
          margin: 20px 0;
        }
      }
      .fieldset.my_message {
        width: 90%;
        margin-bottom: 20px;
        @include breakpoint($small-down) {
          width: 100%;
        }
        textarea {
          width: 95%;
          background: none;
          padding-top: 10px;
          @include breakpoint($small-down) {
            width: 100%;
          }
        }
      }
      .fieldset.my_emailaddress {
        width: 90%;
        input.field {
          width: 95%;
        }
      }
      @include breakpoint($small-down) {
        .fieldset.my_emailaddress {
          width: 100%;
          input.field {
            width: 100%;
          }
        }
      }
      .fieldset.my_order {
        width: 90%;
        @include breakpoint($small-down) {
          width: 100%;
        }
      }
      .field-input {
        .btn_submit {
          input,
          & + .button-loading {
            width: 65%;
            margin-top: 5px;
            @include breakpoint($small-down) {
              margin-top: 25px;
              width: 100%;
            }
          }
        }
      }
      .check_signup {
        width: 90%;
        margin: 10px 0px 10px 0px;
        .field-label {
          float: left;
          margin-right: 15px;
        }
        .field-input {
          input {
            float: left;
            margin-top: 3px;
          }
          label {
            width: 96.5%;
            @include breakpoint($small-down) {
              width: 95%;
            }
          }
        }
      }
    }
  }
  .sidebar-page__sidebar {
    border: none;
    .collapsible-sub-menu {
      margin-bottom: 20px;
    }
  }
  &__contacts {
    @include pie-clearfix;
    margin: 0 auto 30px auto;
    @include breakpoint($landscape-up) {
      display: block;
      border-bottom: 1px solid $color-black;
      padding: 0 0 25px 0;
      margin: 0 0 30px 0;
      .cs-page-landing & {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
  &__contacts-section {
    display: flex;
    justify-content: center;
  }
  &__contacts-item {
    font-size: 20px;
    display: block;
    margin: 0;
    text-align: center;
    width: 33.333%;
    float: left;
    padding: 0 15px;
    position: relative;
    @include breakpoint($landscape-up) {
      font-size: 22px;
    }
    img {
      display: block;
      margin: 0 auto;
      height: auto;
      max-width: 100%;
    }
    .live-chat__chat-button,
    #lpbuttoncs {
      a {
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
        text-indent: -13000px;
        cursor: pointer;
        border: none;
        background: none;
      }
    }
    /* To override Live position style */
    .LPMcontainer {
      &.LPMoverlay {
        position: absolute !important;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.section-customer-service {
  .cs-page {
    &__content {
      &.sidebar-page__content {
        height: auto !important;
      }
    }
  }
}
