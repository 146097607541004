///
/// @file template_api/endeca/_esearch_forms.scss
///
/// \brief Search Block for site header area; form on search page
///
/// @todo test in header and redo styles
/// @setup new brand - these styles can be customized per design
///

// @todo check main variables, see if we need them here
.search-form {
  padding: 20px 10px 24px 10px;
  .esearch-nav & {
    padding: 40px 10px 38px 10px;
  }
  @include breakpoint($medium-up) {
    padding: 40px 10px 38px 10px;
  }
}

.esearch-nav__form-fields,
.search-form__fields {
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  .icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    color: $color-white;
    line-height: 50px;
    font-size: 30px;
    background: $color-black;
  }
}

input[type='text'].esearch-nav__form-field,
input[type='text'].search-form__field {
  height: 50px;
  line-height: 48px;
  padding-#{$rdirection}: 52px;
  width: 100%;
  text-align: center;
}

input[type='submit'].esearch-nav__form-submit,
input[type='submit'].search-form__submit {
  @include squish-text;
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  opacity: 0;
  z-index: 2;
}

.search-form {
  //  width: 100%;
  //   @include breakpoint($medium-up) {
  //     padding: 25px 43px 25px 25px;
  //     width: 744px;
  //   }
  // &__fields {
  //   @include pie-clearfix;
  // }
  // input[type="text"].search-form__field,
  // input[type="submit"].search-form__submit {
  //   display: block;
  // }
  // input[type="text"].search-form__field {
  //   width: 100%;
  //   background: transparent;
  //   height: auto;
  //   padding: 0;
  //   text-transform: uppercase;
  //   &:focus {
  //     border-color: $color-black;
  //   }
  //   @include breakpoint($medium-up) {
  //     border: 1px solid $color-black;
  //     //width: $pc-search-width;
  //     padding: 0 7px;
  //     float: left;
  //     &:focus {
  //       border-color: $color-black;
  //     }
  //   }
  // }
  // input[type="submit"].search-form__submit {
  //   display: block;
  //   margin: 0 0 0 5px;
  //   float: left;
  // }
  // &__results {
  //   @include breakpoint($medium-up) {
  //     //max-width: $pc-search-width;  // width of text field
  //   }
  // }
  .typeahead-wrapper {
    text-align: left;
    width: 100%;
  }
  // .search-suggestions__link {
  //   font-size: 14px;
  //   display: block;
  //   padding: 0 7px 0 0;
  //   @include breakpoint($medium-up) {
  //     padding: 0 5em;
  //   }
  // }
  // .result {
  //   padding: 1em 0;
  //   border-bottom: solid 1px #ccc;
  //   a,
  //   a:active {
  //     text-decoration: none;
  //     color: $color-black;
  //   }
  // }

  &--results {
    margin: 0 auto;
    position: relative;
    // background-color: #fff;
    min-height: $mobile_search_header_height; // Same height as .search-results__header--mobile. We do this so that it completely covers it.
    @include breakpoint($medium-up) {
      min-height: auto;
    }
    .search-form__results-count {
      display: none;
      position: absolute;
      border-top: solid 1px $color-black;
      border-bottom: solid 1px $color-black;
      right: auto;
      width: 100%;
      text-align: center;
      padding: 0 12px;
      @include breakpoint($medium-up) {
        //right: 169px;
        width: auto;
        text-align: left;
      }
    }
  }
}

// Search results page:
.search-results {
  padding: 0;
  @include breakpoint($medium-up) {
    padding: 0 20px;
  }
}
// @todo check styles
.search-results__header--mobile {
  background: #ebebeb;
  text-align: center;
  height: $mobile_search_header_height;
  line-height: $mobile_search_header_height;
}

.search-results__header--desktop {
  @include breakpoint($medium-up) {
    display: block;
  }
}

@include breakpoint($medium-up) {
  .search-results__header--mobile {
    display: none;
  }
}

// @todo check styles
// we may need logic like this?
// .endeca-product-result {
//   .product__button--add-to-bag {
//     display: none;
//   }
//   // &--1 means shaded, &--0 means un-shaded
//   &--0 {
//     @include breakpoint($small-down) {
//       .product__button--add-to-bag {
//         display: inline-block;
//       }
//       .product__button--quickshop {
//         display: none;
//       }
//     }
//   }
// }

.esearch-nav__popular {
  .typeahead-wrapper {
    text-align: center;
    margin: 15px 0;
    .esearch-nav__popular-header {
      display: inline-block;
      font-family: $font--avenir-black;
      font-size: 19px;
      line-height: 25px;
      letter-spacing: 0.025em;
      text-transform: uppercase;
      margin-bottom: 18px;
    }
    .esearch-nav__popular-list {
      font-family: $font--avenir-medium;
      font-size: 16px;
      li {
        padding-bottom: 5px;
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.site-header__section--utilities {
  .contextual-links-region {
    position: static;
  }
}
