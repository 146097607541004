/**
 * Generic typeahead
 */
.typeahead-wrapper {
  margin-top: 10px;
  @include breakpoint($medium-up) {
    margin: 0;
  }
  .see-results {
    margin-bottom: 18px;
    a:not(.search-suggestions__link) {
      font-family: $font--avenir-book;
      font-size: 19px;
      color: $color-gray;
    }
  }
  .esearch-nav__suggestions {
  }
  .search-suggestions__item {
    text-align: center;
    margin: 20px 0;
  }
  .search-suggestions__link {
    @include link;
  }
  .esearch-nav-products,
  .product-results {
    @include clearfix;
    .esearch-product--typeahead {
      float: #{$ldirection};
      width: 25%;
    }
    .product.result {
      margin-bottom: 18px;
      &.last {
        margin-bottom: 0;
      }
      a {
        font-family: $font--avenir-heavy;
        font-size: 16px;
        line-height: 22px;
        text-decoration: none;
      }
    }
  }
  .typeahead-search__see-all-results {
  }
  @include breakpoint($medium-up) {
    .product-grid__content {
      border-top: 0;
    }
  }
  // @setup new brand - this can be removed
  // temp image FPO
  .endeca-test-image--small {
    width: 100%;
  }
}

/**
 * Search form in page content - special positioning
 */
.site-content {
  .search-form {
    position: relative;
    .typeahead-wrapper {
      position: absolute;
      left: 0;
      z-index: 999;
      background-color: $color-white;
      padding: 1em;
      width: 100%;
      border-bottom: 1px solid $color-gray;
      .product-results {
        @include content-container;
      }
    }
  }
}
