// multi column
.subnav__menu__formatter__items {
  @include breakpoint($medium-up) {
    //@include clearfix;
    display: flex;
    flex-flow: row wrap;
  }
}

.subnav__menu__formatter__item {
  @include breakpoint($medium-up) {
    //float: $ldirection;
    .subnav__menu__count--1 & {
      width: 100%;
    }
    .subnav__menu__count--2 & {
      width: 50%;
    }
    .subnav__menu__count--3 & {
      width: 33.3%;
    }
    .subnav__menu__count--4 & {
      width: 25%;
    }
    .subnav__menu__count--5 & {
      width: 20%;
    }
    .subnav__menu__count--6 & {
      width: 16.6%;
    }
  }
}

.subnav__menu__url {
  display: block;
}

.subnav__menu__header {
  font-family: $font--avenir-black;
  font-size: 19px;
  line-height: 30px;
  letter-spacing: 0.025em;
  text-align: center;
  margin: 20px 0;
}

.subnav__menu__image {
  width: 100%;
}

// max container
.subnav__menu__shop,
.subnav__menu__collections,
.subnav__menu__inspiration {
  @include content-container;
}

// shop
.menu__item--shop {
}

.subnav__menu__shop {
  text-align: $ldirection;
  margin-top: 16px;
  .subnav__menu__formatter__items {
  }
  .subnav__menu__formatter__item {
    @include swap_direction(padding, 0 8px 0 40px);
    //padding-#{$ldirection}: 40px; //16.6%;
    border-#{$rdirection}: 1px solid $color-border;
    width: 20%;
    margin: 12px 0 20px 0;
    &:last-child {
      @include swap_direction(padding, 0 50px 0 40px);
      width: 40%;
      margin: 0;
    }
  }
  // header
  .menu__link--lvl-1 {
    display: inline-block;
    font-family: $font--avenir-black;
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    margin-bottom: 18px;
    + .menu--lvl-2 {
      .menu__link--lvl-1 {
        display: inline;
        font-family: $font--avenir-medium;
        font-size: 16px;
        line-height: 27px;
        letter-spacing: 0.025em;
        text-transform: lowercase;
        margin-bottom: 0;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .gnav__cta {
    .gnav__cta__header {
      font-family: $font--avenir-heavy;
      font-size: 28px;
      line-height: 48px;
      letter-spacing: -0.01em;
      text-transform: capitalize;
      text-align: center;
    }
    .gnav__cta__container {
      position: relative;
    }
    .gnav__cta__image {
      width: 100%;
    }
    .gnav__cta__button__container {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
    }
    .gnav__cta__button {
      display: inline-block;
      font-family: $font--avenir-medium;
      font-size: 19px;
      line-height: 45px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      background: $color-black;
      color: $color-white;
      padding: 0 15px;
      margin-bottom: 30px;
    }
  }
}

// collections
.menu__item--collections {
}

.subnav__menu__collections {
  .subnav__menu__formatter__item {
    @include breakpoint($medium-up) {
      width: 20%;
    }
    &:last-child {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
        width: 80%;
      }
    }
  }
}

.subnav__collections__menu {
  text-align: #{$ldirection};
  @include breakpoint($medium-up) {
    @include swap_direction(margin, 26px 0 8px 40px);
  }
  .menu__link {
    @include swap_direction(padding, 0 10px 0 60px);
    display: block;
    font-family: $font--avenir-book;
    font-size: 16px;
    color: $color-black;
    line-height: 25px;
    text-transform: lowercase;
    @include breakpoint($medium-up) {
      padding: 0;
      display: inline;
      font-family: $font--avenir-medium;
      line-height: 30px;
      letter-spacing: 0.025em;
      text-transform: none;
    }
    &:hover {
      @include breakpoint($medium-up) {
        text-decoration: underline;
      }
    }
  }
}

.subnav__carousel__formatter {
  opacity: 0;
  @include transition(opacity, 0.2s ease-out);
  &.subnav__carousel__formatter--active {
    opacity: 1;
  }
}

.subnav__collections__carousel {
  width: 120%;
  padding-#{$ldirection}: 40px;
  //@include min-height(307px);
  //height: 307px;
  .subnav__carousel__item {
    position: relative;
  }
  .subnav__carousel__image {
    width: 100%;
  }
  .subnav__carousel__link {
    position: absolute;
    bottom: 0;
    #{$ldirection}: 0;
    width: 100%;
    display: block;
    background: $color-transparent--black;
    font-family: $font--avenir-medium;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 0.05em;
    color: $color-white;
    padding: 0 8px;
  }
  .slick-prev {
    #{$ldirection}: -60px;
  }
  .slick-next {
    #{$ldirection}: 78%;
    &:before {
      #{$rdirection}: auto;
    }
  }
}

// top-sellers dropdown
.menu__item--top {
  .menu--lvl-2 {
    .menu__list--lvl-1 {
      @include breakpoint($medium-up) {
        text-align: center;
        margin: 20px 0;
      }
      .menu__item--lvl-1 {
        @include breakpoint($medium-up) {
          display: inline-block;
          margin: 0 48px;
        }
        .menu__link--lvl-1 {
          @include swap_direction(padding, 0 10px 0 60px);
          display: block;
          font-family: $font--avenir-book;
          font-size: 16px;
          color: $color-black;
          line-height: 25px;
          letter-spacing: 0.025em;
          //text-transform: lowercase;
          @include breakpoint($medium-up) {
            padding: 0;
            font-family: $font--avenir-black;
            font-size: 19px;
            line-height: 30px;
            //text-transform: capitalize;
          }
        }
      }
    }
  }
}

// products (mobile only)
.mobile-gnav-active {
  .site-header__primary__menu {
    .menu__item--products {
      .menu--lvl-2a {
        .menu__link--lvl-1 {
          @include swap_direction(padding, 0 10px 0 60px);
          display: block;
          font-family: $font--avenir-heavy;
          font-size: 16px;
          color: $color-black;
          line-height: 30px;
          letter-spacing: 0.025em;
          text-transform: uppercase;
          text-align: #{$ldirection};
        }
        .menu--lvl-2 {
          display: none;
          .menu__link--lvl-1 {
            @include swap_direction(padding, 0 10px 0 75px);
            display: block;
            font-family: $font--avenir-book;
            font-size: 16px;
            color: $color-black;
            line-height: 25px;
            letter-spacing: 0.025em;
            text-transform: lowercase;
          }
        }
      }
    }
  }
}

// wants and needs (mobile only)
.mobile-gnav-active {
  .site-header__primary__menu {
    .menu__item--wants {
      .menu--lvl-2a {
        .menu__link--lvl-1 {
          @include swap_direction(padding, 0 10px 0 60px);
          display: block;
          font-family: $font--avenir-book;
          font-size: 16px;
          color: $color-black;
          line-height: 25px;
          letter-spacing: 0.025em;
          text-transform: lowercase;
        }
      }
    }
  }
}
