$loyalty__enabled: true !default;

@import './overrides/overrides';
@import '../../bumble_base/scss/bumble-base-pc';
@import 'layout/header';
@import 'template_api/navigation/subnav_menu';
@import 'layout/navigation_us';
@import 'template_api/navigation/email_signup_us';
@import 'template_api/products/replenishment';
@import 'ada';
@import 'all';
@import 'sections/account';

//One Trust Cookie Stylings
@import '../../bumble_base/scss/sections/one_trust_cookie/cookie_settings_ot';

.store-locator__hero {
  @include breakpoint($large-screen-up) {
    height: 720px;
    padding-top: 10%;
  }
  .store-locator {
    &__hero-title {
      @include breakpoint($ipad-pro-portrait-up) {
        @include swap_direction(padding, 6.5% 0 0 14%);
        line-height: 1;
        font-size: 36px;
      }
      @include breakpoint($mediumPC-up) {
        margin-#{$ldirection}: auto;
        padding: 0;
        line-height: 1;
        font-size: 53px;
      }
    }
    &__search-mode {
      @include breakpoint($ipad-pro-portrait-up) {
        padding-#{$ldirection}: 27%;
        .is-results & {
          padding: 0;
        }
      }
      @include breakpoint($mediumPC-up) {
        padding-#{$ldirection}: 10%;
      }
      .store-locator {
        &__toggle-intl-link {
          color: $color-black;
        }
        &__zip-search-input {
          @include breakpoint($ipad-pro-portrait-up) {
            width: 472px;
          }
          @include breakpoint($mediumPC-up) {
            width: 90%;
          }
        }
        &__toggle-intl {
          @include breakpoint($ipad-pro-portrait-up) {
            text-align: $ldirection;
            margin-#{$ldirection}: 23%;
          }
        }
      }
    }
  }
}

.store-locator.is-results {
  height: 900px;
}
