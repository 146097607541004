.page-not-found {
  margin: 0 auto 40px auto;
  max-width: 567px;
  text-align: center;
  @include breakpoint($medium-up) {
    margin: 23px auto 100px auto;
  }
}

.page-not-found__image {
  width: 100%;
}

.page-not-found__copy {
  font-family: $font--avenir-medium;
  font-size: 16px;
  line-height: 27px;
  margin-top: 16px;
  @include breakpoint($medium-up) {
    font-family: $font--avenir-light;
    font-size: 19px;
  }
}

.page-not-found__link {
  font-family: $font--avenir-heavy;
  @include breakpoint($medium-up) {
    font-family: $font--avenir-light;
  }
}
