.optanon-show-settings-popup-wrapper {
  display: inline-block;
  .optanon-show-settings-button {
    .optanon-show-settings-left,
    .optanon-show-settings-middle,
    .optanon-show-settings-right {
      background: none !important;
      border: 0 !important;
      height: auto !important;
      width: auto !important;
    }
    .optanon-show-settings-middle {
      text-decoration: underline;
      float: none;
      padding-top: 0;
      .optanon-show-settings {
        color: $color-black !important;
        font-weight: normal;
        font-family: $font--avenir-roman;
        font-size: 14px;
        @include breakpoint($medium-up) {
          font-size: 16px;
        }
        &:hover {
          color: $color-gray !important;
        }
      }
    }
    .optanon-show-settings-right {
      float: none;
    }
  }
}

.optanon-status-editable,
.optanon-status-on {
  input[type='checkbox'] {
    & ~ label {
      &:before {
        display: none;
      }
    }
  }
}
