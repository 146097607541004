/* Dropdown control */
a.selectBox {
  font-family: $font--avenir-light;
  font-size: 19px;
  color: $color-gray;
  cursor: pointer;
  min-width: 100%; // Otherwise breaks on resize
  max-width: 100%;
  vertical-align: middle;
  text-align: $ldirection;
  height: 45px;
  line-height: 45px;
  background: $color-white;
  &:focus {
    outline: none;
  }
}

.selectBox-label {
  @include icon('form_dropdown-arrow', after);
  display: inline-block;
  position: relative;
  padding: 0 65px 0 15px;
  height: 100%;
  border-bottom: 2px solid $color-black;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  background: $color-white;
  @include breakpoint($medium-up) {
    border-width: 1px;
    padding-right: 70px;
  }
  &:after {
    color: $color-white;
    position: absolute;
    height: 100%;
    top: 0;
    #{$rdirection}: 0;
    width: 40px;
    line-height: 43px;
    text-align: center;
    border: 2px solid $color-black;
    background: $color-black;
    font-size: 10px;
    @include breakpoint($medium-up) {
      background: $color-white;
      color: $color-black;
      border-width: 1px;
      border-bottom: 0;
      width: 45px;
    }
  }
  // open state
  .selectBox-menuShowing & {
    @include icon('close', after);
    &:after {
      font-size: 16px;
      line-height: 43px;
      background: $color-black;
      color: $color-white;
    }
  }
}

// Alternate Version
a.selectBox--alternate {
  color: $color-black;
  height: 35px;
  line-height: 35px;
  background: none;
  .selectBox-label {
    @include font-smoothing;
    font-family: $font--avenir-heavy;
    color: $color-black;
    border: 0;
    background: none;
    padding-right: 43px;
    font-size: 16px;
    &:after {
      color: $color-black;
      background: none;
      border: 0;
      top: -6px;
      font-size: 9px;
    }
  }
  // open state
  &.selectBox-menuShowing {
    .selectBox-label {
      @include icon('form_dropdown-arrow', after);
      color: $color-gray;
      &:after {
        color: $color-gray;
        line-height: 43px;
        font-size: 9px;
      }
    }
  }
}
/* Dropdown menu */
.selectBox-dropdown-menu {
  @include font-smoothing;
  font-family: $font--avenir-heavy;
  position: absolute;
  max-height: 235px;
  margin: 0;
  padding: 10px 0 15px 0;
  background: $color-black;
  color: $color-white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 99999;
  /* Options */
  a {
    display: block;
    padding: 0 30px 0 25px;
    height: 30px;
    line-height: 30px;
    white-space: nowrap;
    cursor: pointer;
    color: $color-white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &.selectBox--alternate-selectBox-dropdown-menu {
    background: $color-bg;
    color: $color-black;
    a {
      color: $color-black;
    }
  }
}
